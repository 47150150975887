@import 'styles/utils';

.additional-document {
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-12px;
  box-shadow: $protals-form-shadow;
  width: 100%;

  &__title {
    border-bottom: 1px solid $color-gray-200;
    color: $color-gray-900;
    font-size: $font-24px;
    font-weight: $font-weight-600;
    line-height: $line-height-32px;
    margin: $spacing-none;
    padding: $spacing-32px $spacing-24px $spacing-24px;
  }

  &__section {  
    border-bottom: 1px solid $color-gray-200;

    &-header {
      border-bottom: 1px solid $color-gray-100;
      color: $color-gray-500;
      font-size: $font-14px;
      font-weight: $font-weight-600;
      line-height: $line-height-20px;
      margin: $spacing-none;

      &--required {
        color: $color-error-600;
        font-weight: $font-weight-500;
        margin-left: $spacing-5px;
      }
    }
  }

  &__divider {
    border-bottom: 1px solid $color-gray-100;
  }

  &__submit-btn {
    background-color: $color-primary-600;
    border-radius: $border-radius-8px;
    color: $maastricht-blue;
    font-size: $font-14px;
    font-weight: $font-weight-600;
    line-height: $line-height-20px;
  }
}
