@import 'styles/utils';

.portal-profile {
  &__avatar-container {
    padding: $spacing-32px 5%;
  }

  &__verification-buttons {
    padding-right: 5%;

    &--reject,
    &--request,
    &--approve,
    &--back {
      font-family: inherit;
      font-weight: $font-weight-600;
    }

    .button {
      gap: $spacing-8px;

      .button__icon {
        .icon {
          svg {
            fill: inherit;
          }
        }
      }

      &:hover {
        .button__icon {
          .icon {
            svg {
              fill: $white;
            }
          }
        }
      }
    }
  }

  &__tabs {
    align-items: self-start;
    width: 90%;

    .rtabs__tabs {
      border: 1px solid $color-gray-200;
      border-radius: $border-radius-12px;
      box-shadow: $protals-form-shadow;
      height: auto;
      padding: $spacing-20px $spacing-16px;
      width: 20%;

      .rtabs__tab {
        border-left: none;
        border-radius: $border-radius-6px;
        color: $color-gray-600;
        font-size: $font-16px;
        font-weight: $font-weight-600;
        line-height: $line-height-24px;
        margin-bottom: $spacing-4px;
        padding: $spacing-8px $spacing-12px;

        &--selected,
        &:hover {
          background-color: $color-gray-100;
          color: $color-gray-900;
        }
      }
    }

    .rtabs__panel {
      margin-left: $spacing-24px;
      padding-top: $spacing-none;
      width: 100%;
    }
  }

  &__table {
    .admin-portal__table--container {
      margin-left: auto;
      margin-right: auto;
      margin-top: $spacing-24px;
      width: 90%;
    }
  }
}
