@import 'styles/utils';

.personal-details {
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-12px;
  box-shadow: $protals-form-shadow;
  width: 100%;

  &__title {
    border-bottom: 1px solid $color-gray-200;
    color: $color-gray-900;
    font-size: $font-24px;
    font-weight: $font-weight-600;
    line-height: $line-height-32px;
    margin: $spacing-none;
    padding: $spacing-32px $spacing-24px $spacing-24px;
  }

  &__form {
    &--title {
      border-bottom: 1px solid $color-gray-100;
      color: $color-gray-500;
      font-size: $font-14px;
      font-weight: $font-weight-600;
      line-height: $line-height-20px;
      margin: $spacing-none;
      padding-bottom: $spacing-12px;
    }

    &--col1 {
      padding-right: 4px !important;
    }

    &--col2 {
      padding-left: 4px !important;
    }
  }

  &__submit-btn {
    background-color: $color-primary-600;
    border-radius: $border-radius-8px;
    box-shadow: 0px 1px 2px 0px #1018280d;
    color: $maastricht-blue;
    font-size: $font-14px;
    font-weight: $font-weight-600;
    line-height: $line-height-20px;
    margin-top: $spacing-56px;
  }

  &__draft-btn {
    border-radius: $border-radius-8px;
    box-shadow: 0px 1px 2px 0px #1018280d;
    color: $maastricht-blue;
    font-size: $font-14px;
    font-weight: $font-weight-600;
    line-height: $line-height-20px;
    margin-top: $spacing-56px;
    margin-left: $spacing-8px;
  }
}
