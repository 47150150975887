.login {
  height: 100vh;
  width: 100%;

  &__left-container {
    background-image: url('../../assets/images/log-in-left-panel-pattern.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 45%;
  }

  &__right-container {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 55%;
  }
}
