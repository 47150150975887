@import 'styles/utils';

.details-information-card {
  font-weight: $font-weight-600;

  &:not(:last-child) {
    margin-bottom: $spacing-16px;
  }

  &__header {
    align-items: flex-start;
    background: $color-gray-50;
    border: 1px solid $color-gray-200;
    border-top-left-radius: $border-radius-10px;
    border-top-right-radius: $border-radius-10px;
    color: $color-gray-500;
    display: flex;
    flex-direction: column;
    font-size: $font-14px;
    gap: $spacing-6px;
    line-height: $line-height-20px;
    padding: $spacing-12px $spacing-24px;
  }

  &__body {
    border: 1px solid $color-gray-200;
    border-bottom-left-radius: $border-radius-10px;
    border-bottom-right-radius: $border-radius-10px;
    border-top: 0;
    padding: $spacing-20px $spacing-24px;

    &-item {
      color: $color-gray-600;
      display: flex;
      font-size: $font-16px;
      gap: $spacing-20px;
      line-height: $line-height-24px;

      &:not(:last-child) {
        margin-bottom: $spacing-8px;
      }

      &--label {
        min-width: 240px;
      }

      &--separator {
        margin-left: $spacing-20px;
        margin-right: $spacing-20px;
      }

      &--data {
        font-weight: $font-weight-400;
      }
    }
  }
}
