@import 'styles/utils';

.offer-declaration {
  background-color: $white;
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-12px;

  &__heading {
    color: $color-gray-900;
    font-size: $font-24px;
    font-weight: $font-weight-600;
    line-height: $line-height-32px;
    margin: 0;
    padding: $spacing-24px;
    padding-top: $spacing-32px;
  }

  &__body {
    border-bottom: 1px solid $color-gray-200;
    border-top: 1px solid $color-gray-200;
    padding: $spacing-24px;
    padding-bottom: $spacing-48px;
  }

  &__subsection:not(:last-child) {
    margin-bottom: $spacing-60px;
  }

  &__text {
    color: $color-gray-600;
    font-size: $font-16px;
    line-height: $line-height-24px;
    margin: 0;

    &--link {
      color: $maastricht-blue;
      font-weight: $font-weight-600;
      text-decoration: underline;
    }

    &--bold {
      color: $maastricht-blue;
      font-weight: $font-weight-600;
      text-decoration: underline;
    }

    &--error {
      color: $color-error-500;
      font-size: $font-14px;
    }
  }

  &__footer {
    align-items: center;
    background-color: $color-gray-25;
    border-radius: $border-radius-12px;
    display: flex;
    justify-content: space-between;
    padding: $spacing-12px $spacing-24px;

    svg {
      fill: $maastricht-blue;
      margin-right: $spacing-16px;
    }

    &--text {
      color: $color-gray-600;
      line-height: $line-height-24px;
      margin: 0;

      &-bold {
        color: $maastricht-blue;
        font-weight: $font-weight-600;
        margin-right: $spacing-6px;
      }
    }

    &--button {
      border: 1px solid $color-gray-300;
      border-radius: $border-radius-8px;
      color: $color-gray-700;
      font-weight: $font-weight-600;
      padding: $spacing-10px $spacing-18px;
    }
  }
}
