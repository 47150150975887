@import '../../../styles/utils';

@mixin icon-with-custom-fill($color: $primary-text) {
  .icon {
    svg {
      fill: $color;
    }
  }
}

.input {
  &,
  * {
    box-sizing: border-box;
  }

  &__field {
    border: none;
    color: $blueish-black;
    font-family: 'Inter', sans-serif;
    font-size: $font-16px;
    font-weight: $font-weight-400;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    &:hover {
      border-color: $polo-blue;
    }

    &:focus {
      border-color: $polo-blue;
      outline: none;
    }

    &::placeholder {
      color: $color-gray-500;
      font-family: inherit;
      font-size: $font-16px;
      font-weight: $font-weight-400;
    }

    &-holder {
      align-items: center;
      background: $white;
      border: 1px solid $blueish-black-16;
      border-radius: 8px;
      box-shadow: 0 1px 3px $blueish-black-8;
      display: flex;
      overflow: hidden;
      padding: 0 10px;

      &:hover {
        border-color: $polo-blue;
      }

      &:focus {
        border-color: $polo-blue;
        outline: none;
      }

      &--tiny {
        height: 2rem;
      }

      &--small {
        height: 2.5rem;
      }

      &--normal {
        height: 2.75rem;
      }

      &--large {
        height: 3rem;
      }

      &--huge {
        height: 3.5rem;
      }
    }

    &--large {
      &,
      &::placeholder {
        font-size: $font-20px;
      }
    }

    &--huge {
      &,
      &::placeholder {
        font-size: $font-24px;
      }
    }

    &--disabled {
      border-color: $periwinkle-gray;
      opacity: 0.8;
      pointer-events: none;

      &::placeholder {
        color: transparent;
      }
    }

    &--error,
    &--error:hover,
    &--error:focus {
      border-color: $danger;
    }

    &-tooltip-icon {
      margin-left: 5px;

      > .rcl-popover__parent {
        display: block;
      }
    }

    &-tooltip-wrapper {
      font-size: 16px;
    }

    &-tooltip {
      &.rcl-tooltip.rcl-popover {
        padding: 6px 10px;

        .rcl-popover__content {
          font-size: 12px;
        }

        .rcl-popover__tip {
          &--bottom-left,
          &--top-left {
            left: $input-tooltip-tip-offset;
          }

          &--bottom-right,
          &--top-right {
            right: $input-tooltip-tip-offset;
          }

          &--left-top,
          &--right-top {
            top: calc(#{$input-tooltip-tip-offset} * 2);
          }

          &--left-bottom,
          &--right-bottom {
            bottom: calc(#{$input-tooltip-tip-offset} * 2);
          }
        }
      }
    }
  }

  &--with-error {
    &,
    &:hover {
      border-color: $danger;
    }
  }

  &--disabled {
    &:hover {
      border-color: $periwinkle-gray;
    }
  }

  &__label {
    align-items: center;
    color: $color-gray-700;
    display: flex;
    font-weight: $font-weight-500;
    margin: 0 0 6px;
    line-height: $line-height-20px;

    &,
    &--tiny,
    &--small {
      font-size: $font-14px;
    }

    &--large {
      font-size: $font-20px;
    }

    &--huge {
      font-size: $font-24px;
    }

    &--disabled {
      opacity: 0.8;
      pointer-events: none;
    }

    &--required {
      &::after {
        color: $blueish-black;
        content: '*';
        margin-left: 5px;
        color: $color-error-600;
      }
    }
  }

  &__pre-icon {
    @include icon-with-custom-fill;
    margin-right: 5px;

    &--small {
      font-size: 1rem;
    }

    &--tiny {
      font-size: 0.875rem;
    }

    &--large {
      font-size: 1.25rem;
    }

    &--huge {
      font-size: 1.5rem;
    }

    &--disabled {
      @include icon-with-custom-fill($posh-sorrel);
    }
  }

  &__post-icon {
    @include icon-with-custom-fill;
    margin-left: 5px;

    &--small {
      font-size: 1rem;
    }

    &--tiny {
      font-size: 0.875rem;
    }

    &--large {
      font-size: 1.25rem;
    }

    &--huge {
      font-size: 1.5rem;
    }

    &--disabled {
      @include icon-with-custom-fill($posh-sorrel);
    }
  }

  &__clear-icon {
    margin-left: 5px;

    .icon-button {
      padding-left: 0;
      width: auto;
    }
  }

  &-full-width {
    width: 100%;

    .input__field-holder {
      width: 100%;
    }
  }
}
