@import 'styles/utils';

.create-listing {
  background-color: $color-gray-50;
  border-top: 1px solid $color-gray-200;
  padding: $spacing-32px 5%;

  &__stepper {
    border: 1px solid $color-gray-200;
    border-radius: $border-radius-12px $border-radius-12px 0 0;
    padding: $spacing-none;

    .stepper {
      box-shadow: none;

      &__footer {
        border: 0;
        padding: 0;
      }
    }
  }
}

.stb {
  background: $neon-green;
  bottom: calc($spacing-16px * 4.8);
  height: $spacing-64px;
  position: fixed;
  right: 4%;
  width: $spacing-64px;

  .button {
    &__icon {
      margin-right: 0;
    }
  }

  &--icon {
    svg {
      fill: $white;
    }
  }
}
