@import 'styles/utils';

.additional-document {
  &__modal {
    padding: $spacing-24px;
    width: 40rem;

    &--title {
      color: $color-gray-900;
      font-family: Inter;
      font-size: $font-18px;
      font-weight: $font-weight-600;
      line-height: $line-height-28px;
      margin: 0;
    }

    .button {
      border-radius: $border-radius-8px;
      font-weight: $font-weight-600;
      padding: $spacing-10px $spacing-18px;
    }

    .text-field__box-tooltip-wrapper {
      top: $spacing-32px;
    }

    textarea:hover {
      border: 0;
      outline: none;
    }

    textarea:focus {
      border: 0;
      outline: none;
    }
  }

  &__textarea {
    margin-bottom: $spacing-32px;
    margin-top: $spacing-20px;

    .text-field {
      &__label {
        color: $color-gray-700;
        font-family: Inter;
        font-size: $font-14px;
        font-weight: $font-weight-500;
        line-height: $line-height-20px;
      }
    }

    .text-field-holder {
      border: 1px solid $color-gray-300;
      height: 6rem;

      .text-field__box {
        font-size: $font-16px;

        &::placeholder {
          color: $color-gray-500;
          font-size: $font-16px;
        }
      }
    }
  }
}
