@import 'styles/utils';

.divider {
  align-items: center;
  display: flex;
  justify-content: center;

  &-line {
    background: $color-gray-200;
    height: 1px;
    width: 100%;
  }

  &-text {
    color: $color-gray-500;
    font-size: $font-12px;
    font-weight: $font-weight-400;
    line-height: $line-height-18px;
    padding: $spacing-10px $spacing-8px;
  }
}
