@import 'styles/utils';

.loading-spinner {
  height: $spacing-32px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: $spacing-32px;

  &__relative {
    position: relative;
    top: 5%;
  }
}

.loading-spinner span {
  animation: spinner-anim 0.8s linear infinite;
  border: 5px solid transparent;
  border-radius: 50%;
  border-right-color: $neon-green;
  display: block;
  height: $spacing-32px;
  width: $spacing-32px;
}

@keyframes spinner-anim {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
