@import 'styles/utils';

.current-bid {
  color: $color-gray-600;
  font-size: $font-14px;
  font-weight: $font-weight-600;
  line-height: $line-height-20px;
  padding: $spacing-12px $spacing-24px $spacing-24px;

  &-header {
    align-items: center;
    background: $color-gray-50;
    border: 1px solid $color-gray-200;
    border-radius: $border-radius-10px $border-radius-10px 0 0;
    display: flex;
    justify-content: space-between;

    &-title {
      padding: $spacing-12px $spacing-16px;
    }

    &-bids {
      font-weight: $font-weight-400;
      margin-right: $spacing-16px;
    }
  }

  &-body {
    border: 1px solid $color-gray-200;
    border-radius: 0 0 $border-radius-10px $border-radius-10px;
    padding: $spacing-16px;

    &-supporting-text {
      font-weight: $font-weight-400;
      margin-bottom: $spacing-20px;

      &--no-bid {
        margin-bottom: -$spacing-none;
      }
    }

    &-data {
      margin-bottom: $spacing-20px;
      white-space: normal;

      &--admin-portal {
        margin-bottom: $spacing-none;
      }

      &--currency {
        color: $color-gray-900;
        font-size: $font-24px;
        line-height: $line-height-32px;
        padding-right: $spacing-8px;
        white-space: nowrap;
      }

      &--unit {
        color: $color-gray-500;
        font-weight: $font-weight-400;
        white-space: nowrap;
      }

      &--your-bid {
        background-color: $neon-green;
        color: black;
        border-radius: $border-radius-10px;
        padding: $spacing-8px;
        margin-left: $spacing-16px;
        white-space: nowrap;
      }
    }

    &-retract-bid-button,
    &-counter-bid-button {
      background: $base-white;
      border: 1px solid $color-gray-300;
      border-radius: $border-radius-8px;
      box-shadow: $spacing-none $spacing-1px $spacing-2px $spacing-none $light-box-shadow;
      color: $color-gray-700;
      cursor: pointer;
      font-size: $font-16px;
      font-weight: $font-weight-600;
      line-height: $line-height-24px;
      padding: $spacing-10px $spacing-18px;
      width: 100%;
      margin-bottom: $spacing-10px;

      &--disabled {
        background: $white;
        border: 1px solid $color-gray-200;
        color: $color-gray-300;
        display: flex;
        align-items: center;
        gap: $spacing-5px;
        justify-content: center;
      }
    }

    &--accept-bid {
      background: $color-primary-600;
      border: 1px solid $color-primary-600;
      border-radius: 8px;
      box-shadow: 0 1px 2px 0 $light-box-shadow;
      color: $color-gray-900;
      cursor: pointer;
      font-size: $font-16px;
      font-weight: $font-weight-600;
      line-height: $line-height-24px;
      padding: $spacing-10px $spacing-18px;
      width: 100%;

      &__disabled {
        background: $tea-green;
        border: $spacing-none solid $color-primary-200;
        color: $color-gray-500;
        cursor: default;
      }

      &-retract-note {
        margin-top: $spacing-10px;
        font-weight: $font-weight-400;
        display: flex;
        flex-direction: row;
      }
    }
  }
}
