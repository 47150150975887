@import 'styles/utils';

.button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

@mixin generate-modified-button($theme-color, $fg-color: $white) {
  background: $theme-color;
  border: none;
  color: $fg-color;

  .icon svg {
    fill: $white;
  }

  &:hover {
    background: $theme-color;
    border: none;
    color: $white;
  }

  &:active {
    background: $theme-color;
    border: none;
    color: $white;
  }
}

%button {
  align-items: center;
  background: $white;
  border: 1px solid $tertiary-accent-light;
  border-radius: 4px;
  box-sizing: border-box;
  color: $blueish-black;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  font-weight: $font-weight-400;
  justify-content: center;
  line-height: 1.5em;
  padding: 3px 16px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

.button,
a.button {
  @extend %button;

  .icon svg {
    fill: $primary-text;
  }

  &:hover {
    background: $blueish-black-8;
    border: 1px solid rgba(0, 57, 63, 0.24);
    color: $blueish-black;
  }

  &:active {
    background: $white;
    border: 1px solid $tertiary-accent-light;
    color: $blueish-black;
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &-dark {
    background: $cool-gray-24;
    border: 1px solid $cool-gray-18;
    color: $cool-gray-80;

    &:hover {
      background: $cool-gray-18;
      border: 1px solid transparent;
      color: $cool-gray-90;
    }

    &:active {
      background: $cool-gray-95;
      border: 1px solid transparent;
      color: $cool-gray-30;
    }

    &--over-image {
      background: rgba($cool-gray-30, 0.9);
      border: 1px solid $cool-gray-24;
      color: $cool-gray-80;

      &:hover {
        background: rgba($cool-gray-24, 0.9);
        border: 1px solid transparent;
        color: $cool-gray-90;
      }

      &:active {
        background: rgba($cool-gray-95, 0.9);
        border: 1px solid $cool-gray-93;
        color: $cool-gray-30;
      }
    }
  }

  &--normal {
    padding: 9px 15px;
  }

  &--large {
    font-size: 18px;
    height: 48px;
    padding: 12px 18px;
  }

  &--huge {
    font-size: 20px;
    height: 56px;
    line-height: 1.45;
    padding: 15px 21px;
  }

  &--small {
    font-size: 14px;
    height: 32px;
    padding: 4px 16px;
  }

  &--tiny {
    font-size: 12px;
    padding: 3px 9px;
  }

  &--disabled {
    border: 1px solid $blueish-black-24;
    opacity: 0.4;
    pointer-events: none;
  }

  &--full-width {
    display: block;
    width: 100%;
  }

  &--primary {
    @include generate-modified-button($primary-accent);
  }

  &--secondary {
    @include generate-modified-button($secondary-accent);
  }

  &--danger {
    @include generate-modified-button($danger);
  }

  &--success {
    @include generate-modified-button($color-primary-600, $maastricht-blue);

    &:hover {
      @include generate-modified-button($kelly-green);
    }
  }

  &--warning {
    @include generate-modified-button($warning);
  }

  &--tertiary {
    @include generate-modified-button($tertiary-accent);
  }

  &--info {
    @include generate-modified-button($info);
  }

  &--over-image {
    background: rgba($white, 0.9);
    border: 1px solid $cool-gray-94;
    color: $cool-gray-30;

    &:hover {
      background: rgba($cool-gray-94, 0.9);
      border: 1px solid transparent;
      color: $cool-gray-20;
    }

    &:active {
      background: rgba($cool-gray-35, 0.9);
      border: 1px solid $cool-gray-30;
      color: $cool-gray-90;
    }
  }

  &--plain {
    background: none;
    border: none;
  }

  &__icon {
    display: inline-block;
    line-height: 10px;
    margin: 0 5px 0 0;

    svg {
      height: 16px;
      width: 16px;
    }
  }
}
