@import 'styles/utils';

.signed-document {
  &__modal {
    width: 30rem;

    .icon-button {
      &:hover {
        background-color: transparent;
      }
    }

    &--divider {
      border-top: 1px solid $color-gray-100;
      margin: 0;
    }

    &--title {
      color: $color-gray-900;
      font-size: $font-18px;
      font-weight: $font-weight-600;
      line-height: $line-height-28px;
      margin: $spacing-24px 0 $spacing-16px $spacing-24px;
    }

    &--button-wrapper {
      display: flex;
      justify-content: center;
      padding: $spacing-24px;
    }

    .button {
      border-radius: $border-radius-8px;
      font-weight: $font-weight-600;
      padding: $spacing-10px $spacing-18px;
      width: 50%;
    }
  }

  &__textarea {
    margin-bottom: $spacing-32px;
    margin-top: $spacing-20px;

    .text-field {
      &__label {
        color: $color-gray-700;
        font-size: $font-14px;
        font-weight: $font-weight-500;
        line-height: $line-height-20px;
      }
    }

    .text-field-holder {
      border: 1px solid $color-gray-300;
      height: 6rem;

      .text-field__box {
        font-size: $font-16px;

        &::placeholder {
          color: $color-gray-500;
          font-size: $font-16px;
        }
      }
    }
  }
}
