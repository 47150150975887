@import 'styles/utils';

.smaller-screen-warning-modal {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 75vw;
  z-index: 500;

  .modal__container {
    border-radius: $border-radius-12px;
    padding: $spacing-14px;
  }

  .modal__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &__description {
    color: $color-gray-600;
    line-height: $line-height-20px;
  }

  &__icon {
    background-color: $color-warning-100;
    border-radius: 50%;
    margin-bottom: $spacing-16px;
    padding: $spacing-12px;

    svg {
      fill: $color-warning-600;
    }
  }

  &__title {
    color: $color-gray-900;
    font-size: $font-18px;
    font-weight: $font-weight-600;
    line-height: $line-height-28px;
    margin: 0;
    margin-bottom: $spacing-4px;
  }

  &__button {
    border-radius: $border-radius-8px;
    font-size: $font-16px;
    font-weight: $font-weight-600;
    margin-top: $spacing-4px;
  }
}

@media only screen and (min-width: $large-desktop) {
  .smaller-screen-warning-modal {
    display: none;
  }
}
