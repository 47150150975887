@import 'styles/utils';

.Toastify {
  &__toast {
    padding: 20px;
    color: $white;

    &--error {
      background-color: $danger;
    }

    &--success {
      background-color: $success;
    }

    &-container {
      .new-message-toast {
        &__body {
          align-items: flex-start;
        }

        &__title {
          color: $posh-sorrel;
          font-weight: $font-weight-300;
        }

        &__cta {
          margin-top: 12px;
        }

        &__cta-btn,
        &__message-heading {
          align-items: flex-start;
          color: $primary-text;
          font-size: $font-16px;
        }

        &__message-icon {
          margin-top: $spacing-2px;
        }

        &__cta-btn--highlight {
          color: $primary-accent;
        }

        &__message {
          flex-basis: 100%;
        }

        &__message-body {
          color: $primary-text-70;
          font-size: $font-14px;
          line-height: 1.5;
          margin-bottom: 0;
        }
      }

      &--rtl {
        .new-message-toast {
          direction: rtl;

          &__message {
            margin-right: 14px;
          }

          &__cta-btn {
            margin-left: 12px;
            margin-right: auto;
          }
        }
      }
    }
  }

  &__close-button {
    opacity: 1;
    position: absolute;
    right: 12px;
    top: 12px;

    &--rtl {
      left: 12px;
      right: auto;
    }
  }
}
