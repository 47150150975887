@import 'styles/variables';

.wholesale-marketplace {
  background-color: $color-gray-50;

  &__header {
    background-color: $color-primary-50;
    margin-bottom: $spacing-none;

    .banner-with-button__sub-heading {
      &--text {
        color: $color-primary-600;
      }
    }

    .banner-with-button__heading {
      &--text {
        color: $maastricht-blue;
      }
    }

    .banner-with-button__cta-button {
      border-radius: $border-radius-8px;
    }
  }

  &__listing-container {
    padding-top: $spacing-40px;
    width: 61%;

    &--line-chart {
      width: 100%;
    }
  }

  &__trading-cards {
    background-color: $base-white;
    padding: $spacing-96px $spacing-none;

    &--subheading {
      color: $color-primary-600;
      font-size: $font-16px;
      font-weight: $font-weight-600;
      line-height: $line-height-24px;
      margin: auto auto $spacing-12px;
      width: 86%;
    }

    &--heading {
      color: $maastricht-blue;
      font-size: $font-36px;
      font-weight: $font-weight-600;
      letter-spacing: -$letter-space-72px;
      line-height: $line-height-44px;
      margin: auto auto $spacing-64px;
      width: 86%;
    }

    &--container {
      width: 86%;

      &-left,
      &-right {
        width: 46%;
      }
    }
  }
}
