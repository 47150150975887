@import 'styles/utils';

.choose-file {
  width: 100%;

  &__label {
    color: $color-gray-700;
    font-size: $font-14px;
    font-weight: $font-weight-500;
    line-height: $line-height-20px;
  }

  &__file-name {
    color: $color-gray-600;
    font-size: $font-14px;
    line-height: $line-height-20px;
  }

  &__hint {
    color: $color-primary-800;
    cursor: pointer;
    font-size: $font-14px;
    font-weight: $font-weight-600;
    line-height: $line-height-20px;
  }

  &__input {
    display: none;
  }
}
