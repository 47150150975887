@import 'styles/variables';

.nav-header,
.nav-header * {
  box-sizing: border-box;
}

.nav-header {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-family: 'Inter', sans-serif;
  font-weight: $font-weight-400;
  height: 5rem;
  justify-content: center;

  &__transparent {
    background: transparent;
    color: $color-gray-200;
    margin-bottom: $spacing-26px;
    position: absolute;
    width: 100%;
    z-index: 100;
  }

  &__container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 $spacing-32px;
    width: 85%;

    &--left {
      align-items: center;
      display: flex;
      gap: $spacing-26px;
      width: 70%;
    }
  }

  &__content {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-right: 0;

    &--logo {
      align-items: flex-start;
      display: flex;

      &-title {
        flex-shrink: 0;
        height: 0.93525rem;
        width: 2.89806rem;
      }
    }
  }

  &__navigation--list,
  &__navigation--actions-list {
    align-items: center;
    display: flex;
    gap: $spacing-32px;
    margin: $spacing-none;
    padding-left: $spacing-18px;
  }

  &__navigation--actions-item {
    list-style: none;
  }

  &__navigation--actions-button {
    align-items: center;
    background-color: $neon-green;
    border: 0;
    border-radius: $border-radius-8px;
    color: $color-gray-900;
    display: flex;
    font-size: $font-16px;
    font-style: normal;
    font-weight: $font-weight-600;
    gap: $spacing-8px;
    justify-content: center;
    line-height: 1.5;
    outline: none;
    padding: 0.625rem 1.125rem;
    text-decoration: none;

    &.button-signup {
      background: $color-gray-900;
      border: 1px solid $color-gray-900;
      box-shadow: $button-box-shadow;
      color: $neon-green;
    }

    &:hover {
      background-color: transparent;
      border: 1px solid $neon-green;
      cursor: pointer;
      color: $neon-green;
    }
  }
}
