@import 'styles/utils';

.place-counter-bid-modal {
  width: 480px;

  &__header {
    border: 1px solid $color-gray-200;
    display: flex;
    justify-content: space-between;
    padding: $spacing-24px $spacing-24px $spacing-21px;

    &--text {
      color: $color-gray-900;
      font-size: $font-18px;
      font-weight: $font-weight-600;
      line-height: $line-height-28px;
    }
  }

  &__offer-price {
    padding: $spacing-20px $spacing-24px $spacing-none;

    &-input {
      border-bottom: 1px solid $color-gray-200;
      padding-bottom: $spacing-24px;

      &--message {
        border-bottom: none;
        padding-bottom: $spacing-none;
      }

      &--warning {
        .input--with-error {
          border-color: $color-warning-500;
        }

        .icon--danger svg {
          fill: $color-warning-500;
        }
      }

      &--error {
        .input--with-error {
          border-color: $color-error-500;
        }

        .icon--danger svg {
          fill: $color-error-500;
        }
      }

      .input__field-holder:hover .input__field-holder:focus {
        border-color: $neon-green;
      }
    }

    &-warning {
      border-bottom: 1px solid $color-gray-200;
      color: $color-gray-600;
      display: flex;
      font-size: $font-14px;
      font-weight: $font-weight-400;
      gap: $spacing-4px;
      line-height: $line-height-20px;
      padding-bottom: $spacing-24px;
      padding-top: $spacing-5px;
    }
  }

  &__total {
    border-bottom: 1px solid $color-gray-200;
    padding: $spacing-20px $spacing-24px $spacing-none;

    &--text,
    &--price {
      color: $color-gray-900;
      font-size: $font-16px;
      font-weight: $font-weight-600;
      margin-bottom: $spacing-8px;
    }

    &--breakdown {
      font-size: $font-16px;
      padding-bottom: $spacing-24px;
      text-align: right;
    }
  }

  &__cta {
    display: flex;
    gap: $spacing-12px;
    padding: $spacing-24px;

    &-cancel,
    &-bid {
      cursor: pointer;
      font-size: $font-16px;
      font-weight: $font-weight-600;
      line-height: $line-height-24px;
      padding: $spacing-10px $spacing-18px;
      width: 50%;
    }

    &-cancel {
      background: $white;
      border: 1px solid $color-gray-300;
      border-radius: $border-radius-8px;
      color: $color-gray-700;
    }

    &-bid {
      background: $color-primary-600;
      border: 1px solid $color-primary-600;
      border-radius: $border-radius-8px;
      color: $color-gray-900;

      &--disabled {
        background: $tea-green;
        border: $spacing-none solid $color-primary-200;
        color: $color-gray-500;
      }
    }
  }
}
