@import './variables';

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.space-around {
  justify-content: space-around;
}

.space-evenly {
  justify-content: space-evenly;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.gap-4px {
  gap: $spacing-4px;
}

.gap-8px {
  gap: $spacing-8px;
}

.gap-12px {
  gap: $spacing-12px;
}

.gap-14px {
  gap: $spacing-14px;
}

.gap-16px {
  gap: $spacing-16px;
}

.gap-20px {
  gap: $spacing-20px;
}

.gap-24px {
  gap: $spacing-24px;
}

.gap-32px {
  gap: $spacing-32px;
}
