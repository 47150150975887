@import '../../../styles/utils';

.custom-checkbox {
  border-radius: $border-radius-12px;
  padding: $spacing-16px $spacing-20px $spacing-16px $spacing-16px;
  cursor: pointer;

  &-selected {
    background-color: $color-primary-50;
    border: 2px solid $color-primary-600;
  }

  &-not-selected {
    border: 1px solid $color-gray-200;
  }
  &-disabled {
    opacity: 0.5;
  }

  &__label {
    color: $color-gray-700;
    font-size: $font-16px;
    font-weight: $font-weight-600;
    line-height: $line-height-24px;
  }

  &__input-field {
    &--hidden {
      display: none;
    }
  }
}
