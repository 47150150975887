@import 'styles/utils';

$table-shadow-color: hsla(0, 0%, 67.1%, 0.117647);

.table {
  box-sizing: border-box;
  color: $primary-text;
  padding-bottom: 8px;
  width: 100%;

  * {
    box-sizing: border-box;
  }

  &__body {
    height: 50px;
  }

  &__head {
    > tr {
      background-color: transparent;

      > th {
        background: $color-gray-25;
        border: none;
        color: $color-gray-900;
        font-size: $font-12px;
        font-weight: $font-weight-500;
        padding: $spacing-12px $spacing-24px;
      }
    }
  }

  &__row {
    background-color: $white;
    border-bottom: 1px solid $color-gray-200;
  }

  &__table,
  &__body {
    width: 100%;
  }

  &__table {
    border-collapse: collapse;
    font-size: 14px;
  }

  &__field {
    border-top: 1px solid rgba($blueish-black, 0.16);
    font-weight: $font-weight-300;
    padding: 16px 24px;

    &-label {
      display: none;
      font-size: 12px;
      margin-top: 5px;
    }

    &--header,
    &-label {
      border-top: 0;
      color: $color-gray-600;
      font-weight: $font-weight-400;
    }

    &--align-left {
      text-align: left;
      text-align-last: left;
    }

    &--align-center {
      text-align: center;
      text-align-last: center;
    }

    &--align-right {
      text-align: right;
      text-align-last: right;
    }

    &--uppercase {
      text-transform: uppercase;
    }

    &--lowercase {
      text-transform: lowercase;
    }

    &--capitalize {
      text-transform: capitalize;
    }

    &--unavailable {
      color: $secondary-text;
      text-transform: capitalize;
    }
  }

  &__caption-text {
    &--left {
      text-align: left;
      text-align-last: left;
    }

    &--center {
      text-align: center;
      text-align-last: center;
    }

    &--right {
      text-align: right;
      text-align-last: right;
    }
  }

  &--no-shadow {
    box-shadow: none;
  }

  &--no-padding {
    padding: 0;
  }
}

@include viewport-is(desktop-tab) {
  .table {
    background-color: transparent;
    box-shadow: none;

    &__table,
    &__body {
      display: block;
    }

    &__head {
      display: none;
    }

    &__row {
      background: $white;
      border: 0;
      border-radius: 3px;
      box-shadow: 0 1px 3px rgba($tertiary-accent, 0.16);
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 8px;
      padding: 20px;
      width: 100%;
    }

    &__field {
      border: none;
      display: flex;
      flex-basis: 33.3333%;
      flex-direction: column;
      justify-content: flex-end;
      order: var(--order-in-smaller-screen);

      &-label {
        display: block;
      }

      &--align-left,
      &--align-center,
      &--align-right {
        text-align: left;
        text-align-last: left;
      }

      &--full-width {
        flex-basis: 100%;
      }

      &--top-left {
        flex-basis: 50%;
        flex-grow: 1;
        order: -2;
      }

      &--top-right {
        align-items: flex-end;
        flex-basis: 50%;
        flex-grow: 0;
        justify-content: center;
        order: -1;
      }
    }
  }
}

@include viewport-is(tab-mobile) {
  .table {
    &__field {
      flex-basis: 50%;

      &--full-width {
        flex-basis: 100%;
      }
    }
  }
}

@include viewport-is(mobile) {
  .table {
    &__field {
      flex-basis: 100%;

      &--full-width {
        flex-basis: 100%;
      }

      &--top-left,
      &--top-right {
        flex-basis: auto;
      }
    }
  }
}
