@import 'styles/utils';

.retract-bid {
  &__button {
    background: $base-white;
    border: 1px solid $color-gray-300;
    border-radius: $border-radius-8px;
    box-shadow: $spacing-none $spacing-1px $spacing-2px $spacing-none $light-box-shadow;
    color: $color-gray-700;
    cursor: pointer;
    font-size: $font-16px;
    font-weight: $font-weight-600;
    line-height: $line-height-24px;
    padding: $spacing-10px $spacing-18px;
    width: 100%;
    margin-bottom: $spacing-10px;

    &--disabled {
      background: $white;
      border: 1px solid $color-gray-200;
      color: $color-gray-300;
      display: flex;
      align-items: center;
      gap: $spacing-5px;
      justify-content: center;
    }
  }
}
