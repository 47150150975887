@import '../../../styles/utils.scss';

.banner-container {
  background: linear-gradient(45deg, $maastricht-blue 0%, $waikawa-grey 100%);
  height: 100%;
  padding: $spacing-none $spacing-96px $spacing-none $spacing-96px;

  &__logo {
    margin-top: $spacing-32px;
    width: 55px;
  }

  &__title {
    color: $base-white;
    font-size: $font-70px;
    font-weight: $font-weight-500;
    line-height: $line-height-72px;
    margin-bottom: $spacing-none;
  }

  &__message {
    color: $color-gray-200;
    font-size: $font-18px;
    font-weight: $font-weight-500;
    margin-bottom: $spacing-none;
  }

  &__contact-support {
    margin-bottom: $spacing-40px;
    text-decoration: none;

    &--icon {
      svg {
        fill: $color-gray-400;
      }
    }

    &--text {
      color: $color-gray-400;
      font-size: $font-16px;
      margin-bottom: $spacing-none;
      margin-top: $spacing-none;
    }
  }
}
