// ----------------------------------------------------------------------------------
// ---------------------------- Base Components Stylings ----------------------------
// ----------------------------------------------------------------------------------

// Latest colors from styleguide
$cool-gray-3: #191919;
$cool-gray-5: #1a1a1a;
$cool-gray-15: #262626;
$cool-gray-18: #2c2c2c;
$cool-gray-20: #303030;
$cool-gray-20-12: rgba($cool-gray-20, 0.12);
$cool-gray-24: #393939;
$cool-gray-30: #474747;
$cool-gray-35: #525252;
$cool-gray-40: #5e5e5e;
$cool-gray-50: #777777;
$cool-gray-60: #919191;
$cool-gray-66: #999999;
$cool-gray-70: #ababab;
$cool-gray-75: #b9b9b9;
$cool-gray-80: #c6c6c6;
$cool-gray-82: #cbcbcb;
$cool-gray-84: #dbdbdb;
$cool-gray-88: #dddddd;
$cool-gray-90: #e2e2e2;
$cool-gray-91: #e4e4e4;
$cool-gray-92: #e8e9e8;
$cool-gray-93: #ebebeb;
$cool-gray-94: #eeeeee;
$cool-gray-95: #f1f1f1;
$cool-gray-96: #f3f3f3;
$cool-gray-99: #fcfcfc;

$color-success-100: #d1fadf;
$color-success-600: #039855;
$color-warning-100: #fef0c7;
$color-warning-600: #dc6803;
$color-warning-500: #f79009;

$medium-carmine: #b44141;
$attracting-peach: #ff605d;
$florid-tar: #182146;
$posh-sorrel: #969aab;
$accent: $medium-carmine;
$primary-accent: #c44a47;
$secondary-accent: #1c355d;
$tertiary-accent: #4b73a7;
$tertiary-accent-light: #e2e8f1;
$wedgewood: #5b7faf;
$lynch: #76869e;
$ghost-gray: #c5c7d0;
$tea-green: #d1ebb8;
$honey-dew: #ecfdf3;

// Viewport declarations for responsive layouts
$large-desktop: 1200px; // large desktops
$desktop-tab: 991px; // desktops, landscape and portrait tabs
$tab-mobile: 767px; // portrait tabs and landscape mobiles
$mobile: 480px; // landscape and portrait mobiles
$small-mobile: 360px; // small landscape and portrait mobiles

// Variables for new grid system
$container-160px: 20rem;
$container-1280px: 80rem;
$text-width: 35rem;

$silver: rgb(204, 204, 204);
$light-gray: lighten($silver, 15);
$dark-gray: darken($silver, 30);
$white: rgb(255, 255, 255);
$white-16: rgba($white, 0.16);
$blue: rgb(43, 61, 79);
$light-blue: lighten($blue, 25);
$athens-gray-blue: rgba(249, 250, 251, 0.7);
$black: #000000;
$black-14: rgba($black, 0.14);
$black-28: rgba($black, 0.28);
$black-70: rgba($black, 0.7);
$rich-black: rgba(0, 57, 63, 0.24);

$green: #008000;
$comestic-chartreuse: #c2c759;
$committed-tawny: #71a667;
$fantastic-royal: #43c3b3;
$lofty-ashen: #5243c7;
$losing-rust: #bf4777;
$outer-bronze: #c28539;
$predictable-shark: #8f57a6;
$shiny-cobalt: #4c9ef2;
$unruly-chartreuse: #f2d464;

$light-box-shadow: rgba(16, 24, 40, 0.05);
$default-shadow: rgba(75, 115, 167, 0.16);
$checkbox-hover-shadow: rgba(136, 140, 157, 0.16);
$separator-color: rgba(0, 57, 63, 0.16);
$image-gradient: linear-gradient(180deg, rgba(16, 24, 40, 0) 25.52%, rgba(75, 163, 13, 0.3) 75%);

// Shades
$grey-shade: rgba(0, 0, 0, 0.08);
$dark-shade1: rgba(16, 24, 40, 0.06);
$dark-shade2: rgba(16, 24, 40, 0.1);

// Other Sizes
$image-height: 17rem;

// Forms
$form-box-border-color: $dark-gray;
$form-box-shadow: inset 0 1px 3px rgba($black, 0.06);
$form-box-shadow-focus:
  $form-box-shadow,
  0 0 5px adjust-color($dark-gray, $lightness: -5%, $alpha: -0.3);
$form-card-shadow: 0 1px 3px rgba(87, 7, 51, 0.12);

// variables from META
$downhill-violet: #2877e4;
$astronomical-silver: #f9f9f9;
$earthly-royal: #3ab974;
$prime-marble: #d0d0d0;
$rightful-chocolate: #e04e3f;
$pale-lime: rgba(171, 204, 40, 0.1);
$casual-azure: #394160;
$leaky-rust: #888c9d;
$leaky-rust-light: #eeeff1;
$dark-lemon-lime: #80cc28;

// Color-Palette
$blueish-black: #00393f;
$blueish-black-8: rgba($blueish-black, 0.08);
$blueish-black-16: rgba($blueish-black, 0.16);
$blueish-black-24: rgba($blueish-black, 0.24);
$blueish-black-40: rgba($blueish-black, 0.4);
$primary-text: $blueish-black;
$primary-text-70: rgba($primary-text, 0.7);
$primary-text-light: rgba($primary-text, 0.08);
$secondary-text: $leaky-rust;
$danger: #ff5555;
$danger-16: rgba($danger, 0.16);
$danger-light: #fce4e4;
$success: #52b987;
$success-16: rgba($success, 0.16);
$success-light: #e1f4eb;
$warning: #ffd938;
$warning-16: rgba($warning, 0.16);
$warning-light: #fdf3e0;

// Field Colors
$periwinkle-gray: #dbe2f1;
$polo-blue: #84a5d0;
$athens-gray: #edeef0;
$subordinate-snow: #eff1f6;
$considerate-royal: #6687b5;

// Tooltip configs in all the components
$input-tooltip-tip-offset: 0;

// New color
$warning-dark: #45300b;
$warning-dark-8: rgba($warning-dark, 0.08);
$warning-dark-16: rgba($warning-dark, 0.16);
$danger-dark: #450b0b;
$danger-dark-8: rgba($danger-dark, 0.08);
$success-dark: #0b4543;
$success-dark-8: rgba($success-dark, 0.08);
$green-cyan: #32b07e;
$sunset-orange: #ff4c50;
$yellow-orange: #ffac3f;

// Primary color
$green: #39a22b;

// Secondary color(Elements)
$dark-blue: #383384;
$light-blue: #6a9bfa;

// System color
$pastal-green: #72b469;
$light-violet: #8084fa;
$normal-red: #ed2424;
$info: #6a9bfa;

// --------------------------------------------------------------------------------
// ---------------------------- MSM Styles Starts Here ----------------------------
// --------------------------------------------------------------------------------

// MSM Color
$base-white: #fff;
$color-gray-25: #fcfcfd;
$color-gray-50: #f9fafb;
$color-gray-100: #f2f4f7;
$color-gray-200: #eaecf0;
$color-gray-300: #d0d5dd;
$color-gray-400: #98a2b3;
$color-gray-500: #667085;
$color-gray-600: #475467;
$color-gray-700: #344054;
$color-gray-900: #101828;
$waikawa-grey: #5b7599;
$gentle-haze: rgba($color-gray-100, 0.95);

$color-blue-50: #eff8ff;
$color-blue-100: #d1e9ff;
$color-blue-600: #1570ef;
$color-blue-700: #175cd3;
$yankees-blue: #1d2939;
$maastricht-blue: #00162d;
$color-midnight-blue: #001633;

$color-primary-50: #f3fee7;
$color-primary-80: #fafef5;
$color-primary-100: #e3fbcc;
$color-primary-300: #a6ef67;
$color-primary-200: #d0f8ab;
$color-primary-600: #4dec20;
$color-primary-700: #39aa19;
$color-primary-800: #3b7c0f;
$neon-green: #57ef23;
$kelly-green: #41b31a;
$dark-green: #267b39;
$android-green: #abcc28;
$android-green-light: #abcc281a;

$color-warning-25: #fffcf5;
$color-warning-200: #fedf89;

$color-error-50: #fef3f2;
$color-error-100: #fee4e2;
$color-error-500: #ff4b34;
$color-error-600: #d92d20;
$color-error-700: #b42318;

// Modal color
$modal-backdrop: rgba(52, 64, 84, 0.7);

// box-shadow
$color-box-shadow-base: rgb(16, 24, 40);
$button-box-shadow: 0 1px 2px 0 rgba($color-box-shadow-base, 0.05);
$dropdown-box-shadow:
  0 4px 6px -2px rgba($color-box-shadow-base, 0.03),
  0 12px 16px -4px rgba($color-box-shadow-base, 0.08);
$protals-form-shadow:
  0 2px 4px -2px $dark-shade1,
  0 4px 8px -2px $dark-shade2;
$table-box-shadow:
  0px 1px 2px 0px rgba(16, 24, 40, 0.06),
  0px 1px 3px 0px rgba(16, 24, 40, 0.1);

// base spacing
$spacing-none: 0;
$spacing-1px: 0.0625rem;
$spacing-2px: 0.125rem;
$spacing-3px: 0.188rem;
$spacing-4px: 0.25rem;
$spacing-5px: 0.313rem;
$spacing-6px: 0.375rem;
$spacing-8px: 0.5rem;
$spacing-10px: 0.625rem;
$spacing-12px: 0.75rem;
$spacing-14px: 0.875rem;
$spacing-16px: 1rem;
$spacing-18px: 1.125rem;
$spacing-20px: 1.25rem;
$spacing-21px: 1.3125rem;
$spacing-24px: 1.5rem;
$spacing-26px: 1.625rem;
$spacing-28px: 1.75rem;
$spacing-32px: 2rem;
$spacing-35px: 2.25rem;
$spacing-36px: 2.25rem;
$spacing-40px: 2.5rem;
$spacing-44px: 2.75rem;
$spacing-48px: 3rem;
$spacing-51px: 3.2rem;
$spacing-55px: 5.5rem;
$spacing-56px: 3.5rem;
$spacing-60px: 3.75rem;
$spacing-64px: 4rem;
$spacing-96px: 6rem;
$spacing-100px: 6.25rem;
$spacing-128px: 8rem;
$spacing-144px: 9rem;
$spacing-150px: 9.385rem;
$spacing-152px: 9.5rem;
$spacing-160px: 10rem;
$spacing-450px: 28.125rem;

// font-size
$font-10px: 0.625rem;
$font-12px: 0.75rem;
$font-14px: 0.875rem;
$font-16px: 1rem;
$font-18px: 1.125rem;
$font-20px: 1.25rem;
$font-24px: 1.5rem;
$font-30px: 1.875rem;
$font-36px: 2.25rem;
$font-40px: 2.5rem;
$font-44px: 2.75rem;
$font-48px: 3rem;
$font-60px: 3.75rem;
$font-70px: 4.375rem;
$font-80px: 5rem;

//font-weight
$font-weight-200: 200;
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;

// Line height
$title-line-height: 2.3rem;
$line-height-16px: 1rem;
$line-height-18px: 1.125rem;
$line-height-20px: 1.25rem;
$line-height-24px: 1.5rem;
$line-height-28px: 1.75rem;
$line-height-30px: 1.875rem;
$line-height-32px: 2rem;
$line-height-38px: 2.375rem;
$line-height-44px: 2.75rem;
$line-height-60px: 3.75rem;
$line-height-72px: 4.5rem;
$line-height-80px: 4rem;

// Letter spacing
$letter-space-72px: 0.72px;
$letter-space-92px: 0.06rem;

// border radius
$border-radius-4px: 0.25rem;
$border-radius-6px: 0.5rem;
$border-radius-8px: 0.5rem;
$border-radius-10px: 0.625rem;
$border-radius-12px: 0.75rem;
$border-radius-16px: 0.5rem;
$border-radius-28px: 1.75rem;
$border-radius-100px: 6.25rem;
$border-radius-circle: 50%;

// height
$height-8px: 0.5rem;
$height-20px: 1.25rem;
$height-28px: 1.75rem;
$height-36px: 2.25rem;
$height-40px: 2.5rem;
$height-48px: 3rem;
$height-96px: 6rem;
$height-280px: 17.5rem;
$height-512px: 32rem;
$height-543px: 33.9375rem;

// width
$width-20px: 1.25rem;
$width-28px: 1.75rem;
$width-48px: 3rem;
$width-240px: 15rem;
$width-280px: 17.5rem;
$width-360px: 22.5rem;
$width-768px: 48rem;
$width-836px: 52.25rem;
$width-1216px: 76rem;

// icon
$dropdown-icon: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNNSA3LjVMMTAgMTIuNUwxNSA3LjUiIHN0cm9rZT0iIzAwMzkzZjNkIiBzdHJva2Utd2lkdGg9IjEuNjY2NjciIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4=';
