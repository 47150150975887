@import 'styles/utils';

.unpublish-offer-modal {
  padding: $spacing-32px;
  width: 640px;

  &__loader {
    &-content {
      align-items: 'center';
      display: 'flex';
      justify-content: 'center';
      min-height: '60px';
      min-width: '60px';

      .loading-spinner {
        position: relative;
      }
    }

    &-text {
      color: $color-gray-700;
      font-size: $font-18px;
      font-weight: $font-weight-500;
      line-height: $line-height-28px;
      margin-top: $spacing-16px;
      text-align: center;
    }
  }

  &__completed {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: $spacing-16px;

    &-icon {
      background: $color-success-100;
      border-radius: $border-radius-28px;
      height: 56px;
      justify-content: center;
      width: 56px;

      svg {
        fill: $color-success-600;
      }
    }

    &-text {
      color: $color-gray-700;
      font-size: $font-18px;
      font-weight: $font-weight-500;
      line-height: $line-height-28px;
    }
  }
}
