@import 'styles/utils';

.business-contacts {
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-12px;
  box-shadow: $protals-form-shadow;
  width: 100%;

  &__common-border {
    border-bottom: 1px solid $color-gray-200;
    border-top: 1px solid $color-gray-200;
  }

  &__title {
    border-bottom: 1px solid $color-gray-200;
    color: $color-gray-900;
    font-size: $font-24px;
    font-weight: $font-weight-600;
    line-height: $line-height-32px;
    margin: $spacing-none;
    padding-bottom: $spacing-24px;
  }

  &__form-title {
    border-bottom: 1px solid $color-gray-100;
    color: $color-gray-500;
    font-size: $font-14px;
    font-weight: $font-weight-600;
    line-height: $line-height-20px;
    padding-bottom: $spacing-12px;

    &--required {
      color: $color-error-600;
      margin-left: $spacing-5px;
    }
  }

  &__hidden-field {
    display: none;
  }

  &__title-container {
    border-bottom: 1px solid $color-gray-100;
    color: $color-gray-500;

    &--header {
      font-size: $font-14px;
      font-weight: $font-weight-600;
      line-height: $line-height-20px;
    }

    &--required {
      color: $color-error-600;
      font-size: $font-14px;
      font-weight: $font-weight-500;
      line-height: $line-height-20px;
    }
  }

  &__uploaded-file {
    color: $color-gray-500;
    font-size: $font-14px;
    font-weight: $font-weight-600;
    line-height: $line-height-20px;
    margin: $spacing-none;
    text-align: right;
  }

  &__upload-file {
    &:not(:last-of-type) {
      border-bottom: 1px solid $color-gray-100;
    }
  }

  &__add-fields {
    background-color: transparent;
    border: 0;
    color: $color-primary-800;
    cursor: pointer;
    font-size: $font-14px;
    font-weight: $font-weight-600;
    line-height: $line-height-20px;

    .icon {
      &--success {
        svg {
          fill: $color-primary-800;
        }
      }
    }

    &:hover {
      background: 0;
      border: 0;
      color: $color-primary-800;
    }
  }

  &__submit-btn {
    background-color: $color-primary-600;
    border-radius: $border-radius-8px;
    color: $maastricht-blue;
    font-size: $font-14px;
    font-weight: $font-weight-600;
    line-height: $line-height-20px;
  }

  &__draft-btn {
    border-radius: $border-radius-8px;
    color: $maastricht-blue;
    font-size: $font-14px;
    font-weight: $font-weight-600;
    line-height: $line-height-20px;
  }
}
