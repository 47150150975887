@import 'styles/utils';

.progress-card-modal {
  &__body {
    align-items: center;
    background: $white;
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    padding: $spacing-24px $spacing-24px $spacing-none;
    width: 25rem;

    &--title {
      color: $color-gray-900;
      font-size: $font-18px;
      font-weight: $font-weight-600;
      line-height: $line-height-28px;
      margin-bottom: $spacing-4px;
      text-align: center;
    }

    &--description {
      color: $color-gray-600;
      font-size: $font-14px;
      font-weight: $font-weight-400;
      line-height: $line-height-20px;
      margin: 0;
      text-align: center;
    }
  }

  &__button-container {
    display: flex;
    gap: $spacing-12px;
    justify-content: space-between;
    margin-top: $spacing-32px;
    padding: $spacing-none $spacing-24px $spacing-24px;
    width: 100%;

    &--button {
      display: flex;
      flex: 1 0 0;
      font-size: $font-16px;
      font-weight: $font-weight-600;
      line-height: $line-height-24px;
      padding: $spacing-10px $spacing-18px;
    }
  }
}
