@import 'styles/utils';

.footer {
  align-items: center;
  background: $color-gray-900;
  color: $white;
  display: flex;
  flex-direction: column;
  gap: $spacing-64px;
  padding: $spacing-64px $spacing-none $spacing-48px;

  &__content {
    display: flex;
    gap: $spacing-64px;
    padding: $spacing-none $spacing-64px;
    width: 67%;

    &--img {
      width: 25%;

      &__icon {
        cursor: pointer;
      }

      &__title {
        color: $neon-green;
        font-size: 30px;
        font-weight: 400;
        line-height: 30px;
      }
    }

    &--links-container {
      display: flex;
      flex: 1 0 0;
      gap: $spacing-64px;

      &-links {
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        gap: $spacing-16px;

        &-group-header {
          color: $neon-green;
          font-size: 20px;
          font-weight: 600;
          line-height: 20px;
        }
      }
    }
  }

  &__bottom {
    align-items: center;
    border-top: 1px solid $color-gray-600;
    display: flex;
    gap: $spacing-64px;
    height: calc($spacing-16px * 5.7);
    justify-content: space-between;
    margin-top: $spacing-16px;
    width: 68%;

    &--social-icon {
      align-items: center;
      display: flex;
      gap: $spacing-16px;
    }
  }
}

.custom-link {
  color: $color-gray-200;
  font-size: $spacing-16px;
  font-weight: $font-weight-600;
  line-height: $spacing-24px;
  text-decoration: none;

  &:hover {
    color: $neon-green;
  }

  &__disabled {
    color: $posh-sorrel;

    &:hover {
      color: $posh-sorrel;
    }
  }
}
