@import 'styles/utils';

.new-message-toast {
  &__title {
    color: $black;
    font-size: $font-12px;
    margin-bottom: $spacing-12px;
    margin-top: $spacing-4px;
  }

  &__body {
    display: flex;
  }

  &__message {
    flex-basis: 80%;
    margin-left: $spacing-14px;
  }

  &__message-heading {
    color: $black;
    display: flex;
    font-size: $font-14px;
    gap: $spacing-8px;
    margin-bottom: 0;
    margin-top: $spacing-2px;
  }

  &__message-body {
    color: $black-70;
    font-size: $font-12px;
    margin-top: $spacing-6px;
  }

  &__cta-btn {
    background: transparent;
    border: none;
    color: $black-70;
    cursor: pointer;
    margin-right: 12px;
    outline: none;
    padding: 0;
  }

  &__cta-btn--highlight {
    color: $black;
  }
}
