@import 'styles/utils';

.sidebar-details {
  background-color: $color-gray-25;
  height: 100vh;
  padding: $spacing-none $spacing-32px $spacing-24px;

  &__tabs {
    align-items: self-start;

    .rtabs__panel {
      padding-bottom: $spacing-none;
      padding-top: $spacing-none;
      width: 80%;
    }

    .rtabs__tabs {
      background-color: $white;
      border: 1px solid $color-gray-200;
      border-radius: $border-radius-12px;
      box-shadow: $protals-form-shadow;
      height: auto;
      margin-right: $spacing-24px;
      padding: $spacing-20px $spacing-16px;
      width: 20%;

      .rtabs__tab {
        border-left: none;
        border-radius: $border-radius-6px;
        color: $color-gray-600;
        font-size: $font-16px;
        font-weight: $font-weight-600;
        line-height: $line-height-24px;
        margin-bottom: $spacing-4px;
        padding: $spacing-8px $spacing-12px;

        &--selected {
          background-color: $color-gray-100;
          color: $color-gray-900;
        }

        &:hover {
          background-color: $color-gray-100;
          color: $color-gray-900;
        }
      }
    }
  }
}
