@import 'styles/utils';

.invitation-modal {
  &__modal {
    padding: $spacing-24px;
    width: 40rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &--title {
      color: $color-gray-900;
      font-family: Inter;
      font-size: $font-18px;
      font-weight: $font-weight-600;
      line-height: $line-height-28px;
      margin: 0;
    }
  }

  &__submit-btn {
    margin-top: $spacing-24px;
    border-radius: $border-radius-8px;
    font-weight: $font-weight-600;
    padding: $spacing-10px $spacing-18px;
  }
}
