@import 'styles/utils';

.modal {
  align-items: center;
  bottom: 0;
  color: $primary-text;
  display: flex;
  font-family: 'Inter', sans-serif;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  text-align: left;
  top: 0;
  z-index: 30;

  &__container {
    background: $white;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    max-height: 80%;
    max-width: 50.4rem;
    opacity: 0;
    padding: 26px;
    position: relative;
    transform: translateY(-25px);
    width: 80%;
    z-index: 100;

    @include viewport-is(mobile) {
      max-width: 100%;
      width: 100%;
    }

    &--use-transition {
      transition:
        opacity 200ms ease-in-out,
        transform 300ms ease-in-out;
    }

    &--transform-visible {
      opacity: 1;
      transform: initial;
    }
  }

  &__backdrop {
    backdrop-filter: blur(8px);
    background: $modal-backdrop;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 250ms ease-out;
    z-index: 99;

    &--transform-visible {
      opacity: 1;
    }
  }
}

// To place all the modals above google map"s +/- and share location buttons and mapquest logo

.leaflet-top,
.leaflet-bottom,
.location__map .location__share {
  z-index: 1;
}
