@import 'styles/utils';

$step-index-width: 32px !default;
$step-connector-padding: 5px !default;
$tertiary-accent-lighter: rgba($tertiary-accent, 0.16);

.stepper {
  background-color: $white;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;

  &__body {
    align-items: center;
    display: flex;
    padding: 20px 0;

    &-step {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      position: relative;
      transition: opacity 0.3s ease;
      width: 100%;

      &-index,
      &-name {
        align-items: center;
        display: flex;
        font-size: 16px;
        justify-content: center;
        width: 100%;
      }

      &-index {
        background-color: $color-gray-400;
        border-radius: 50%;
        color: $white;
        font-weight: $font-weight-500;
        height: $step-index-width;
        width: $step-index-width;
      }

      &-name {
        color: $posh-sorrel;
        font-weight: $font-weight-600;
        margin-top: $spacing-16px;
        text-transform: capitalize;
      }

      &-check-icon {
        svg {
          fill: $white;
        }
      }

      &-connector {
        border-top: 2px solid $color-gray-200;
        left: calc(-50% + #{$step-index-width} / 2 + #{$step-connector-padding});
        position: absolute;
        right: calc(50% + #{$step-index-width} / 2 + #{$step-connector-padding});
        top: calc($step-index-width / 2);

        &::after {
          border-top: 1px solid $white;
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: -1px;
        }
      }

      &--active {
        .stepper__body-step {
          &-index {
            background-color: $neon-green;
          }

          &-name {
            color: $florid-tar;
          }
        }
      }

      &--done {
        .stepper__body-step {
          &-index {
            background-color: $neon-green;
          }

          &-connector {
            border-color: $neon-green;
          }
        }
      }
    }
  }

  &__header,
  &__footer {
    border-top: 1px solid $tertiary-accent-lighter;
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    width: 100%;

    &-step-description {
      align-items: center;
      display: flex;
      font-size: 20px;
      line-height: 1.5;
      text-transform: capitalize;
    }

    &-actions {
      align-items: center;
      column-gap: 8px;
      display: flex;
      margin-left: auto;

      &-button {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
      }
    }
  }

  &__header {
    border-top: none;
    margin-top: 20px;
    padding: 0;
  }

  &--vertical {
    display: flex;
    flex-wrap: wrap;
    width: 250px;

    .stepper__body {
      align-items: stretch;
      flex-wrap: wrap;
      min-height: 450px;
      padding: 0;

      &-step {
        align-content: center;
        min-height: 100px;

        &-connector {
          border-left: 2px solid $ghost-gray;
          border-top: none;
          bottom: calc(50% + #{$step-index-width});
          left: 50%;
          position: absolute;
          right: auto;
          top: calc(-50% + #{$step-index-width});

          &::after {
            border-left: 1px solid $white;
            border-top: none;
            bottom: 0;
            content: '';
            left: -1px;
            position: absolute;
            right: auto;
            top: 0;
          }
        }
      }
    }

    .stepper__footer {
      border-top: none;
      padding: 0 0 20px;

      &-actions {
        display: flex;
        width: 100%;

        > * {
          flex-grow: 1;
        }
      }
    }

    .stepper__body-step--done {
      .stepper__body-step {
        &-connector {
          border-color: $neon-green;
        }
      }
    }
  }
}
