@import '../../../styles/utils';

.signup-form {
  margin: $spacing-48px auto;
  width: 50%;

  &__title {
    color: $color-gray-900;
    font-size: $font-30px;
    font-weight: $font-weight-600;
    line-height: $title-line-height;
    margin: $spacing-none $spacing-none $spacing-12px $spacing-none;
  }

  &__message {
    color: $color-gray-600;
    font-size: $font-16px;
    font-weight: $font-weight-400;
    line-height: $line-height-24px;
    margin: 0 0 $spacing-32px 0;
  }

  &__heading {
    color: $color-gray-700;
    font-weight: $font-weight-500;
    line-height: $line-height-20px;
    margin-top: $spacing-none;
    size: $font-14px;
  }

  &__submit-button {
    background-color: $color-primary-600;
    border-radius: $border-radius-16px;
    color: $maastricht-blue;
    font-size: $font-16px;
    font-weight: $font-weight-600;
    line-height: $line-height-24px;
    margin-bottom: $spacing-6px;
    margin-top: $spacing-12px;
  }

  &__login-message {
    color: $color-gray-600;
    font-size: $font-14px;
    font-weight: $font-weight-400;
    line-height: $line-height-20px;
    margin: $spacing-none;
    text-align: center;

    &--redirect {
      color: $color-primary-800;
      font-size: $font-14px;
      font-weight: $font-weight-600;
      line-height: $line-height-20px;
    }
  }
}
