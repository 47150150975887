@import 'styles/utils';

.accordion-container {
  background-color: $color-primary-80;
  position: sticky;
  top: 0;
  z-index: 150;

  .accordion {
    background-color: $color-primary-80;
    margin-left: auto;
    margin-right: auto;
    width: 84%;

    &__item {
      background-color: $color-primary-80;
      box-shadow: none;
      margin: $spacing-none;
      padding: $spacing-none;

      .icon {
        svg {
          fill: $neon-green;
        }
      }

      &-header {
        background-color: $color-primary-80;
        padding: $spacing-none;
      }

      &-body {
        border: none;
        color: $color-gray-500;
        font-size: $font-16px;
        font-weight: $font-weight-400;
        line-height: $line-height-24px;
        margin-left: 54px;
        padding-top: $spacing-none;

        &--facebook-link {
          color: $neon-green;
          margin-left: $spacing-6px;

          &:hover {
            color: $kelly-green;
          }
        }
      }
    }
  }

  &__heading-container {
    &--title {
      color: $neon-green;
      font-size: $font-16px;
      font-weight: 600;
      line-height: $line-height-24px;
    }
  }

  &__bold-text {
    font-weight: $font-weight-700;
  }
}
