@import 'styles/utils';

.progress-card {
  &__container {
    align-items: flex-start;
    align-self: stretch;
    background: $color-gray-700;
    border-radius: $border-radius-12px;
    box-shadow: $dropdown-box-shadow;
    color: $white;
    display: flex;
    flex-direction: column;
    margin-top: $spacing-24px;
    width: 90%;

    &--head {
      align-items: flex-start;
      align-self: stretch;
      border-bottom: 1px solid $color-gray-600;
      display: flex;
      gap: $spacing-16px;
      padding: $spacing-24px;

      &-text {
        align-items: flex-start;
        color: $color-gray-300;
        display: flex;
        flex: 1 0 0;
        flex-direction: column;

        &-title {
          color: $white;
          margin-bottom: $spacing-8px;
          margin-top: 0;
        }
      }
    }

    &--progress {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      gap: $spacing-32px;
      padding: $spacing-24px;

      &-bar {
        width: 100%;

        .progress-bar {
          background-color: $color-gray-300;
          margin-bottom: $spacing-8px;
          width: 100%;

          &__label-container {
            display: flex;
            justify-content: space-between;
          }

          &__complete-label {
            color: $white;
            font-weight: $font-weight-600;
          }

          &__hint-label {
            color: $color-gray-400;
            font-weight: $font-weight-600;
          }
        }
      }

      &-text {
        align-self: stretch;
        display: flex;
        font-size: $font-16px;
        font-style: normal;
        font-weight: $font-weight-600;
        justify-content: space-between;

        p:last-child {
          color: $color-gray-400;
        }
      }

      &-steps {
        align-self: stretch;
        display: flex;
        flex-direction: column;
        font-weight: $font-weight-600;
        gap: $spacing-16px;
        line-height: $line-height-24px;
        list-style: none;
        padding: 0;
      }
    }
  }

  &__button-verification {
    background-color: $color-primary-50;
    border-radius: $border-radius-8px;
    color: $color-primary-800;
    font-family: inherit;
    font-size: $font-16px;
    font-weight: $font-weight-600;
    line-height: $line-height-24px;
    margin-top: $spacing-24px;
    padding: $spacing-10px $spacing-18px;

    &:hover {
      background-color: $color-primary-50;
      color: $color-primary-800;
    }
  }

  &__icon {
    background-color: $color-warning-100;
    border-radius: 50%;
    padding: $spacing-12px;

    svg {
      fill: $color-warning-600;
    }

    &--success {
      background-color: $color-success-100;
      border-radius: 50%;
      padding: $spacing-12px;

      svg {
        fill: $color-success-600;
      }
    }
  }

  &__tooltip {
    cursor: pointer;

    svg {
      fill: $color-gray-400;
    }
  }
}

.steps-list-number {
  border: 1px solid $color-gray-400;
  border-radius: 50%;
  color: $color-gray-400;
  height: $spacing-32px;
  margin-right: $spacing-16px;
  position: relative;
  width: $spacing-32px;

  &--digit {
    left: 35%;
    position: absolute;
    top: 12%;
  }

  &--complete {
    border: 2px solid $color-primary-600;
    color: $color-primary-600;
  }
}

.steps-separator {
  color: $color-gray-300;
  font-weight: $font-weight-400;
  margin-left: $spacing-12px;
  margin-right: $spacing-12px;
}

.steps-separated-text {
  color: $color-gray-300;
  font-weight: $font-weight-400;
}

.progress-card-modal {
  .modal {
    &__container {
      border-radius: $border-radius-12px;
      padding: 0;
      width: auto;
    }

    &__content {
      border-radius: inherit;
    }
  }
}
