@import 'styles/utils';

.accept-offer-modal {
  width: 450px;

  &__header {
    border: 1px solid $color-gray-200;
    display: flex;
    justify-content: space-between;
    padding: $spacing-24px $spacing-24px $spacing-21px;

    &--text {
      color: $color-gray-900;
      font-size: $font-18px;
      font-weight: $font-weight-600;
      line-height: $line-height-28px;
    }

    &--close-icon {
      padding: $spacing-10px;
    }
  }

  &__total {
    padding: $spacing-20px $spacing-24px $spacing-none;

    &--text,
    &--price {
      color: $color-gray-900;
      font-size: $font-16px;
      font-weight: $font-weight-600;
      line-height: $line-height-24px;
      margin-bottom: $spacing-8px;
    }

    &--breakdown {
      border-bottom: 1px solid $color-gray-200;
      color: $color-gray-600;
      font-size: $font-16px;
      font-weight: $font-weight-400;
      padding-bottom: $spacing-24px;
      text-align: right;
    }
  }

  &__acknowledgement {
    border-bottom: 1px solid $color-gray-200;
    display: flex;
    gap: $spacing-8px;
    padding: $spacing-24px $spacing-24px $spacing-32px;

    &-checkbox {
      padding-top: $spacing-3px;
    }

    .rcl-checkbox__check {
      border: 1.5px solid $neon-green;
    }

    .rcl-checkbox .rcl-checkbox--checked {
      background: $white;
      border: 1.5px solid $neon-green;

      svg {
        fill: $neon-green;
      }
    }

    &-text {
      color: $color-gray-600;
      font-size: $font-16px;
      font-weight: $font-weight-400;

      &--bold {
        color: $color-gray-900;
        font-weight: $font-weight-600;
        text-decoration-line: underline;
      }
    }
  }

  &__cta {
    display: flex;
    gap: $spacing-12px;
    padding: $spacing-24px;

    &--cancel,
    &--accept-offer {
      border-radius: $border-radius-8px;
      cursor: pointer;
      font-size: $font-16px;
      font-weight: $font-weight-600;
      padding: $spacing-10px $spacing-18px;
      width: 50%;
    }

    &--cancel {
      background: $white;
      border: 1px solid $color-gray-300;
      color: $color-gray-700;
    }

    &--accept-offer {
      background: $color-primary-600;
      border: 1px solid $color-primary-600;
      color: $color-gray-900;

      &--disabled {
        background: $tea-green;
        border: $spacing-none solid $color-primary-200;
        color: $color-gray-500;
      }
    }
  }
}
