@import 'styles/utils';

.signup-form {
  margin: $spacing-48px auto $spacing-96px;
  width: 40%;

  &__title {
    color: $color-gray-900;
    font-size: $font-30px;
    font-weight: $font-weight-600;
    line-height: $title-line-height;
    margin: $spacing-none $spacing-none $spacing-12px $spacing-none;
  }

  &__message {
    color: $color-gray-600;
    font-size: $font-16px;
    font-weight: $font-weight-400;
    line-height: $line-height-24px;
    margin: 0 0 $spacing-32px 0;
  }

  &__form-title {
    border-bottom: 1px solid $color-gray-100;
    color: $color-gray-500;
    font-size: $font-14px;
    font-weight: $font-weight-600;
    line-height: $line-height-20px;
    margin: $spacing-24px 0 0 0;
    padding-bottom: $spacing-12px;

    &--required {
      color: $color-error-600;
      margin-left: $spacing-5px;
    }

    &--content {
      color: $color-gray-700;
      font-size: $font-14px;
      font-weight: $font-weight-500;
      line-height: $line-height-20px;
    }
  }

  &__country {
    &--title {
      color: $color-gray-700;
      display: flex;
      font-size: $font-14px;
      font-weight: $font-weight-500;
      line-height: $line-height-20px;
      margin: $spacing-none $spacing-none $spacing-6px;

      &--required {
        color: $color-error-600;
        margin-left: $spacing-5px;
      }
    }

    &--dropdown {
      border: 1px solid $color-gray-300;
      border-radius: $border-radius-8px;
      color: $color-gray-500;
      font-size: $font-16px;
      font-weight: $font-weight-400;
      height: $height-40px;
      line-height: $line-height-24px;
      margin-top: $spacing-6px;

      &--error {
        border: 1px solid $danger;
      }
    }
  }

  &__policy-content {
    .rcl-checkbox__label .rcl-checkbox__label--header {
      color: $color-gray-600;
      font-size: $font-16px;
      font-weight: $font-weight-400;
      line-height: $line-height-24px;
      cursor: text;
    }

    .rcl-checkbox__check {
      margin-top: $spacing-5px;
    }

    &--content1::after {
      content: '\00a0';
    }

    &--redirect-link {
      color: $color-gray-900;
      font-weight: $font-weight-600;
    }

    &--content2::before {
      content: '\00a0';
    }
  }

  &__submit-btn {
    background-color: $color-primary-600;
    border: none;
    border-radius: $border-radius-8px;
    color: $maastricht-blue;
    font-size: $font-16px;
    font-weight: $font-weight-600;
    line-height: 1.5rem;
    width: 100%;

    &:disabled {
      color: $color-primary-200;
    }
  }
}
