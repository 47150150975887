@import 'styles/utils';

.side-nav {
  &__link {
    &--icon {
      svg {
        fill: $color-gray-900;
      }
    }
  }
}
