@import 'styles/variables';

.product-card {
  background-color: $base-white;
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-12px;
  padding: $spacing-16px;
  text-decoration: none;

  &__disable-link {
    pointer-events: none;
  }

  &__header {
    &--text {
      color: $color-gray-900;
      font-size: $font-18px;
      font-weight: $font-weight-600;
      line-height: $line-height-28px;
      margin: $spacing-none;

      &-currency {
        &::after {
          content: ' ';
        }
      }

      &-truncate {
        max-width: 14ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:not(:last-child) {
        border-right: 1px solid $color-gray-400;
      }
    }

    &--label {
      color: $color-gray-600;
      font-size: $font-14px;
      font-weight: $font-weight-400;
      line-height: $line-height-20px;
      margin: $spacing-none;
    }
  }

  &__description {
    color: $color-gray-600;
    font-size: $font-14px;
    font-weight: $font-weight-400;
    line-height: $line-height-20px;

    &--bold {
      font-weight: $font-weight-500;

      &::after {
        content: ' ';
      }
    }

    &--bolder {
      font-weight: $font-weight-600;

      &::before,
      &::after {
        content: ' ';
      }
    }

    &--batch-price {
      font-weight: $font-weight-600;
    }
  }
}
