.border-none {
  border: 0;
}

// --- Margins ---
.margin-none {
  margin: 0;
}

// Margins x
.margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}

.margin-x-0 {
  margin-left: 0;
  margin-right: 0;
}

.margin-x-24px {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

// Margins y
.margin-y-auto {
  margin-bottom: auto;
  margin-top: auto;
}

.margin-y-0 {
  margin-bottom: 0;
  margin-top: 0;
}

.margin-y-16px {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.margin-y-32px {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

// Margins top
.margin-t-0 {
  margin-top: 0;
}

.margin-t-2px {
  margin-top: 2px;
}

.margin-t-8px {
  margin-top: 0.5rem;
}

.margin-t-14px {
  margin-top: $spacing-14px;
}

.margin-t-16px {
  margin-top: 1rem;
}

.margin-t-20px {
  margin-top: 1.25rem;
}

.margin-t-22px {
  margin-top: 1.375rem;
}

.margin-t-24px {
  margin-top: 1.5rem;
}

.margin-t-28px {
  margin-top: 1.75rem;
}

.margin-t-32px {
  margin-top: 2rem;
}

.margin-t-40px {
  margin-top: 2.5rem;
}

.margin-t-60px {
  margin-top: $spacing-60px;
}

.margin-t-64px {
  margin-top: $spacing-64px;
}

.margin-t-96px {
  margin-top: $spacing-96px;
}

// Margins right
.margin-r-32px {
  margin-right: 2rem;
}

// Margins bottom
.margin-b-0 {
  margin-bottom: 0;
}

.margin-b-4px {
  margin-bottom: 0.25rem;
}

.margin-b-8px {
  margin-bottom: 0.5rem;
}

.margin-b-12px {
  margin-bottom: 0.75rem;
}

.margin-b-16px {
  margin-bottom: 1rem;
}

.margin-b-20px {
  margin-bottom: 1.25rem;
}

.margin-b-24px {
  margin-bottom: 1.5rem;
}

.margin-b-30px {
  margin-bottom: 1.875rem;
}

.margin-b-32px {
  margin-bottom: 2rem;
}

.margin-b-38px {
  margin-bottom: 2.3755rem;
}

.margin-b-40px {
  margin-bottom: 2.5rem;
}

.margin-b-64px {
  margin-bottom: $spacing-64px;
}

.margin-b-67px {
  margin-bottom: 2.5rem;
}

// Margins left
.margin-l-8px {
  margin-left: 0.5rem;
}

.margin-l-12px {
  margin-left: 0.75rem;
}

.margin-l-24px {
  margin-left: 1.5rem;
}

.margin-l-32px {
  margin-left: 2rem;
}

// --- Paddings ---
.padding-50px {
  padding: 3.125rem;
}

.padding-24px {
  padding: $spacing-24px;
}

// Paddings x
.padding-x-0 {
  padding-left: 0;
  padding-right: 0;
}

.padding-x-12px {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.padding-x-16px {
  padding-left: 1rem;
  padding-right: 1rem;
}

.padding-x-24px {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

// Paddings y
.padding-y-12px {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}

.padding-y-16px {
  padding-bottom: $spacing-16px;
  padding-top: $spacing-16px;
}

.padding-y-24px {
  padding-bottom: $spacing-24px;
  padding-top: $spacing-24px;
}

.padding-y-32px {
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.padding-y-48px {
  padding-bottom: 3rem;
  padding-top: 3rem;
}

// Paddings top
.padding-t-12px {
  padding-top: 0.75rem;
}

.padding-t-16px {
  padding-top: $spacing-16px;
}

.padding-t-24px {
  padding-top: $spacing-24px;
}

.padding-t-32px {
  padding-top: $spacing-32px;
}

.padding-t-40px {
  padding-top: $spacing-40px;
}

// Paddings right
.padding-r-8px {
  padding-right: 0.5rem;
}

.padding-r-10px {
  padding-right: 0.625rem;
}

.padding-r-12px {
  padding-right: 0.75rem;
}

// Paddings bottom
.padding-b-12px {
  padding-bottom: $spacing-12px;
}

.padding-b-16px {
  padding-bottom: 1rem;
}

.padding-b-32px {
  padding-bottom: 2rem;
}

.padding-b-40px {
  padding-bottom: $spacing-40px;
}

.padding-b-48px {
  padding-bottom: $spacing-48px;
}

.padding-b-60px {
  padding-bottom: $spacing-60px;
}

// Paddings left
.padding-l-8px {
  padding-left: 0.5rem;
}

.padding-l-10px {
  padding-left: 0.625rem;
}

.padding-l-12px {
  padding-left: 0.75rem;
}

.padding-l-16px {
  padding-left: $spacing-16px;
}

.padding-l-24px {
  padding-left: $spacing-24px;
}

.padding-b-24px {
  padding-bottom: $spacing-24px;
}

.padding-l-45px {
  padding-left: 45px;
}

.offer-food-safety-padding {
  border-top: 1px solid $color-gray-200;
  padding: $spacing-32px $spacing-24px $spacing-40px;
}

@media screen and (min-width: 1867px) {
  .margin-b-40px {
    margin-bottom: 4rem;
  }
}
