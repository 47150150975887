@import 'styles/utils';

.form-footer {
  &__content-divider {
    margin: $spacing-32px $spacing-none $spacing-24px;

    &--box {
      background-color: $color-gray-200;
      height: 1px;
      width: 100%;
    }

    &--text {
      line-height: $line-height-20px;
      font-size: $font-14px;
      font-weight: $font-weight-500;
      margin: $spacing-none $spacing-8px;
    }
  }

  &__login-message {
    color: $color-gray-600;
    font-size: $font-14px;
    font-weight: $font-weight-400;
    line-height: $line-height-20px;
    margin: $spacing-none;
    text-align: center;

    &--redirect {
      background: inherit;
      border: 0;
      color: $color-primary-800;
      cursor: pointer;
      font-size: $font-14px;
      font-weight: $font-weight-600;
      line-height: $line-height-20px;
      outline: none;
      text-decoration: none;
    }
  }
}
