@import 'styles/utils';

$transition: all 0.2s ease-in-out;

.breadcrumbs {
  align-items: center;
  box-sizing: border-box;
  color: $cool-gray-75;
  display: flex;
  list-style: none;
  margin-block-end: 0;
  margin-block-start: 0;
  padding: 5px;
  padding-inline-start: 5px;
  width: 100%;

  * {
    box-sizing: border-box;
  }

  &__item {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    flex-shrink: 3;
    font-weight: $font-weight-500;
    min-width: 5ch;
    overflow: hidden;
    transition: $transition;

    a {
      color: $color-gray-600;
      display: block;
      font-size: 14px;
      overflow: hidden;
      padding: 2px 0;
      position: relative;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;

      &::before {
        background-color: $cool-gray-50;
        border-radius: 3px;
        bottom: 0;
        content: '';
        height: 1px;
        position: absolute;
        transition: $transition;
        width: 0;
      }

      &:hover {
        color: $primary-text;
      }

      &:hover::before {
        width: 100%;
      }
    }

    &:last-of-type {
      flex-grow: 1;
      flex-shrink: 1;
      margin-right: 0;
      overflow: hidden;

      > a {
        color: $color-primary-600;
        font-weight: $font-weight-600;
        width: 100%;
      }

      > a:hover::before {
        width: 0;
      }
    }
  }

  .menu {
    &__button {
      height: 30px;
      margin-bottom: 0;
      padding: 0 5px;
      width: 30px;

      .icon-button__icon {
        font-size: 20px;

        svg {
          fill: rgba($primary-text, 0.65);
        }
      }

      &:hover svg {
        fill: $tertiary-accent;
      }
    }

    &__list {
      box-shadow: 0 0 6px $cool-gray-90;
      left: 25%;
      margin-top: 5px;
      transform: translateX(-25%);
    }
  }

  &__barrier {
    color: $color-gray-300;
    margin: 0 8px;
  }
}
