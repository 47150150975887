@import 'styles/variables';

.following-listings {
  &__sort-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    &--dropdown {
      width: $width-280px;
  
      .rcl-select-box {
        &__label {
          &--header {
            color: $maastricht-blue;
          }
        }
      }
    }
  }

  .product-card {
    margin-top: $spacing-16px;
  }
}
