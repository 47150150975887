@import 'styles/utils';

.listing-card {
  background-color: $base-white;
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-12px;

  &__title {
    border-bottom: 1px solid $color-gray-200;
    color: $maastricht-blue;
    font-size: $font-24px;
    font-weight: $font-weight-600;
    line-height: $line-height-32px;
    margin: $spacing-8px $spacing-none $spacing-none;
    padding: $spacing-24px;
  }

  &__container {
    border: 1px solid $color-gray-200;
    border-radius: $border-radius-8px;
    margin: $spacing-24px;
    overflow: hidden;

    &--left {
      background-color: $color-gray-50;
      display: grid;
      grid-row-gap: $spacing-24px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      padding: $spacing-24px;
      width: 80%;
    }

    &--right {
      background-color: $color-gray-50;
      border-left: 1px solid $color-gray-200;
      display: flex;
      flex-direction: column;
      gap: $spacing-20px;
      padding: $spacing-24px;
      width: 20%;
    }

    &--title {
      color: $color-gray-600;
      font-size: $font-16px;
      font-weight: $font-weight-600;
      line-height: $line-height-24px;
      margin: $spacing-none;
      text-transform: capitalize;

      &-description {
        font-weight: $font-weight-400;
        text-transform: none;
      }

      &-uppercase {
        text-transform: uppercase;
      }

      &-empty {
        font-weight: $font-weight-400;
        text-transform: capitalize;
      }
    }
  }

  &__help-icon {
    svg {
      fill: $color-gray-400;
    }
  }

  &__product-card {
    background-color: $color-gray-50;
  }
}
