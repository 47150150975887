@import 'styles/utils';

.request-assistance {
  &__modal {
    width: 30rem;

    &--title {
      margin-bottom: 0;
      margin-left: $spacing-16px;
      padding: $spacing-16px;
    }

    &--subtitle {
      color: $color-gray-600;
      font-size: $spacing-14px;
      font-weight: 400;
      line-height: $spacing-20px;
    }

    &--form-container {
      padding: $spacing-20px $spacing-24px $spacing-32px;

      .rcl-checkbox__check {
        height: $spacing-16px;
        width: $spacing-16px;
      }

      &-text {
        font-size: $spacing-14px;
      }

      &-bottom-content {
        padding: $spacing-16px $spacing-24px;

        &-contact-support {
          background: $white;
          border: 1px solid $color-gray-300;
          border-radius: $spacing-8px;
          box-shadow: 0 1px 2px 0 $light-box-shadow;
          color: $color-gray-700;
          font-size: $spacing-16px;
          font-weight: $font-weight-600;
          line-height: $spacing-24px;
        }

        &-buttons {
          align-items: flex-start;
          align-self: stretch;
          display: flex;
          gap: $spacing-12px;
          padding: 0 $spacing-24px $spacing-24px;

          &-request-assistance {
            border-radius: $spacing-8px;
            color: $color-gray-900;
            font-size: $spacing-16px;
            font-weight: $font-weight-600;
            line-height: $spacing-24px;
            width: 50%;
          }
        }
      }
    }

    &--request-sent {
      align-items: center;
      background: $white;
      border-radius: $spacing-12px;
      box-shadow:
        0 8px 8px -4px rgba(16, 24, 40, 0.03),
        0 20px 24px -4px rgba(16, 24, 40, 0.08);
      display: flex;
      flex-direction: column;
      padding: $spacing-32px;
      width: 40rem;
    }
  }

  &__request-sent-icon {
    background: $color-success-100;
    border: 0 solid $color-success-100;
    border-radius: $border-radius-circle;
    height: $height-48px;
    width: $width-48px;

    svg {
      fill: $success;
      margin: auto;
    }
  }

  &__text-content {
    color: $color-gray-600;
    font-size: $spacing-16px;
    font-weight: $font-weight-400;
    line-height: $line-height-24px;
  }
}
