@import 'styles/utils';

.business-details {
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-12px;
  box-shadow: $protals-form-shadow;
  width: 100%;

  &__title {
    border-bottom: 1px solid $color-gray-200;
    color: $color-gray-900;
    font-size: $font-24px;
    font-weight: $font-weight-600;
    line-height: $line-height-32px;
    margin: $spacing-none;
    padding: $spacing-32px $spacing-24px $spacing-24px;
  }

  &__tooltip-section {
    border-bottom: 1px solid $color-gray-100;
    color: $color-gray-500;
    padding-bottom: $spacing-12px;

    &--header {
      font-size: $font-14px;
      font-weight: $font-weight-600;
      line-height: $line-height-20px;
    }

    &--required {
      color: $color-error-600;
      margin-left: 1px;
    }

    &--file-count {
      color: $color-gray-500;
      font-size: $font-14px;
      font-weight: $font-weight-600;
      line-height: $line-height-20px;
      margin: $spacing-none;
    }
  }

  &__section-header {
    border-bottom: 1px solid $color-gray-100;
    color: $color-gray-500;
    font-size: $font-14px;
    font-weight: $font-weight-600;
    line-height: $line-height-20px;
    margin: $spacing-none;
    padding-bottom: $spacing-12px;

    &--required {
      color: $color-error-600;
      font-weight: $font-weight-500;
      margin-left: $spacing-5px;
    }
  }

  &__country {
    &--title {
      color: $color-gray-700;
      font-size: $font-14px;
      font-weight: $font-weight-500;
      line-height: $line-height-20px;
      margin-bottom: $spacing-6px;
      margin-top: $spacing-none;

      &--required {
        color: $color-error-600;
        margin-left: $spacing-5px;
      }
    }

    &--dropdown {
      border: 1px solid $color-gray-300 !important;
      border-radius: $border-radius-8px !important;
      color: $color-gray-500 !important;
      font-size: $font-14px !important;
      font-weight: $font-weight-400;
      margin-top: 6px;

      &--error {
        border: 1px solid $danger !important;
      }
    }
  }

  &__checkbox {
    .rcl-checkbox__label {
      .rcl-checkbox__label--header {
        color: $color-gray-700;
        font-size: $font-14px;
        font-weight: $font-weight-500;
      }
    }
  }

  &__delivery-option {
    color: $color-gray-700;
    font-size: $font-14px;
    font-weight: $font-weight-500;
    line-height: $line-height-20px;
    margin: $spacing-none;
  }

  &__uploaded-file {
    color: $color-gray-500;
    font-size: $font-14px;
    font-weight: $font-weight-600;
    line-height: $line-height-20px;
    margin: $spacing-none;
    text-align: right;
  }

  &__upload {
    &:not(:last-of-type) {
      border-bottom: 1px solid $color-gray-100;
    }
  }

  &__submit-btn {
    background-color: $color-primary-600;
    border-radius: $border-radius-8px;
    box-shadow: 0 1px 2px 0 #1018280d;
    color: $maastricht-blue;
    font-size: $font-14px;
    font-weight: $font-weight-600;
    line-height: $line-height-20px;
  }

  &__draft-btn {
    border-radius: $border-radius-8px;
    box-shadow: 0 1px 2px 0 #1018280d;
    color: $maastricht-blue;
    font-size: $font-14px;
    font-weight: $font-weight-600;
    line-height: $line-height-20px;
  }
}

.react-flag-dropdown {
  .ReactFlagsSelect-module_disabledBtn {
    &__3A4GF {
      background-color: $base-white;
    }
  }

  .business-details {
    &__country {
      &--dropdown {
        color: inherit;
      }
    }
  }

  &--disabled {
    .ReactFlagsSelect-module_selectBtn {
      &__19wW7::after {
        display: none;
      }
    }
  }
}
