@import 'styles/variables';

.overlay-cta {
  background-color: $base-white;
  margin-bottom: $spacing-96px;
  margin-top: -$spacing-96px;
  width: 61%;

  &__blur-background {
    backdrop-filter: blur(4px);
    height: $height-96px;
  }

  &__text-container {
    border: 1px solid $color-primary-300;
    border-radius: $border-radius-12px;
    box-shadow: $dropdown-box-shadow;
    padding: $spacing-64px $spacing-48px $spacing-48px;

    &--heading {
      color: $color-gray-900;
      font-size: $font-36px;
      letter-spacing: -$letter-space-72px;
      line-height: $line-height-44px;
      margin: $spacing-none;
    }

    &--subheading {
      color: $color-gray-600;
      font-size: $font-18px;
      font-weight: $font-weight-400;
      line-height: $line-height-28px;
      margin: $spacing-none;
    }

    &--signup-button {
      background: $neon-green;
      border: none;
      border-radius: $border-radius-4px;
      box-shadow: $button-box-shadow;
      color: $maastricht-blue;
      font-size: $font-16px;
      font-weight: $font-weight-600;
      gap: $spacing-8px;
      line-height: $line-height-24px;
      padding: $spacing-12px $spacing-20px;
      text-decoration: none;

      &:hover {
        background-color: $kelly-green;
        color: $white;
      }
    }
  }
}
