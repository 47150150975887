@import 'styles/variables';

.banner-with-button {
  background-color: $yankees-blue;
  height: $height-280px;
  margin-bottom: $spacing-96px;
  padding: $spacing-96px $spacing-none;

  &__sub-heading {
    margin-bottom: $spacing-12px;

    &--text {
      color: $color-gray-200;
      font-size: $font-16px;
      font-weight: $font-weight-600;
      line-height: $line-height-24px;
      text-align: center;
    }
  }

  &__heading {
    margin-bottom: $spacing-40px;

    &--text {
      color: $white;
      font-size: $font-48px;
      font-weight: $font-weight-600;
      letter-spacing: -0.96px;
      line-height: $line-height-60px;
      text-align: center;
    }
  }

  &__cta-button {
    background: $neon-green;
    border: none;
    border-radius: $border-radius-4px;
    box-shadow: $button-box-shadow;
    color: $maastricht-blue;
    font-size: $font-16px;
    font-weight: $font-weight-600;
    gap: $spacing-8px;
    line-height: $line-height-24px;
    padding: $spacing-12px $spacing-20px;
    text-decoration: none;

    &:hover {
      background-color: $kelly-green;
      color: $white;
    }
  }
}
