@import 'styles/utils';

.portals-footer {
  padding: $spacing-16px;

  &__text {
    padding: $spacing-2px;
    font-size: $font-16px;
    text-align: center;
  }

  &__link {
    text-decoration: none;
    color: $color-gray-500;
  }
}
