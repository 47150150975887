@import 'styles/utils';

.listing-notification {
  background-color: $color-warning-25;
  border: 1px solid $color-warning-200;
  border-radius: $border-radius-12px;
  box-sizing: border-box;
  margin-top: $spacing-24px;
  padding: $spacing-24px;
  width: 100%;

  &__warning-icon {
    background-color: $color-warning-100;
    border-radius: $border-radius-circle;
    height: $height-48px;
    width: $width-48px;

    svg {
      fill: $color-warning-600;
      margin: auto;
    }
  }

  &__heading,
  &__description,
  &__redirect-button {
    font-size: $font-16px;
    font-weight: $font-weight-600;
    line-height: $line-height-24px;
  }

  &__heading {
    color: $maastricht-blue;
    margin: $spacing-none $spacing-none $spacing-4px;
  }

  &__description {
    color: $color-gray-700;
    font-weight: $font-weight-400;
    margin: $spacing-none;
  }

  &__redirect-button {
    background-color: $color-primary-600;
    border: 1px solid $color-primary-600;
    border-radius: $border-radius-8px;
    box-shadow: $spacing-none $spacing-1px $spacing-2px $spacing-none $light-box-shadow;
    color: $maastricht-blue;
    padding: $spacing-10px $spacing-18px;
    text-decoration: none;
  }
}
