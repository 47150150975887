@import 'styles/utils';

.reset-password-form {
  &__container {
    margin-top: -$spacing-48px;
    width: $width-360px;

    &--header {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      gap: $spacing-24px;

      &-logo {
        align-items: center;
        background-color: $color-primary-100;
        border: 1px solid $color-primary-50;
        border-radius: 50%;
        display: flex;
        height: $spacing-48px + $spacing-8px;
        justify-content: center;
        padding: $spacing-14px;
        width: $spacing-48px + $spacing-8px;

        img {
          height: $spacing-48px;
          width: $spacing-48px;
        }
      }

      &-texts {
        align-items: center;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        gap: $spacing-12px;

        &-title {
          color: $color-gray-900;
          font-size: $font-30px;
          font-weight: $font-weight-600;
          line-height: $title-line-height;
          margin: $spacing-none $spacing-none $spacing-12px;
        }

        &-message {
          color: $color-gray-600;
          font-size: $font-16px;
          font-weight: $font-weight-400;
          line-height: $line-height-20px;
          margin: -$spacing-8px $spacing-none $spacing-32px;
          text-align: center;
        }
      }
    }

    &--form {
      margin-top: -$spacing-16px;

      .input__label {
        &--required::after {
          color: $danger;
        }
      }

      .submit-error {
        color: $danger;
        font-size: $font-14px;
        font-weight: $font-weight-400;
        line-height: $line-height-20px;
      }
    }

    &--submit-btn {
      border: 0;
      border-radius: $border-radius-8px;
      color: $maastricht-blue;
      font-size: $font-16px;
      font-weight: $font-weight-600;
      line-height: $line-height-20px;
      margin-top: $spacing-10px;
      width: 100%;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    margin-top: $spacing-32px;

    &--login-link {
      align-items: center;
      color: $color-gray-600;
      display: flex;
      font-weight: $font-weight-600;
      text-align: center;
      text-decoration: none;

      svg {
        fill: $color-gray-600;
        margin-right: 0.5rem;
      }
    }
  }
}

.reset-form-icon {
  align-items: center;
  background-color: $color-primary-100;
  border: 1px solid $color-primary-50;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding: $spacing-16px;
}
