@import 'styles/utils';

.pagination {
  display: flex;
  font-size: 12px;
  font-weight: 400;
  justify-content: flex-end;

  .button {
    background-color: rgba($white, 0.4);
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba($cool-gray-15, 0.16);
    color: $cool-gray-15;
    margin: 0 2px;

    &--success {
      background-color: $color-primary-800;
      color: $white;
    }
  }
}
