@import 'styles/utils';

.form-header {
  border-radius: $border-radius-8px;
  margin: $spacing-40px $spacing-32px $spacing-48px;

  &__title {
    color: $color-gray-600;
    margin: $spacing-none;
  }

  &__cancel-button {
    border-radius: $border-radius-8px;
    color: $color-gray-700;
    font-size: $font-16px;
    font-weight: $font-weight-600;
    line-height: $line-height-24px;
  }

  &__steps {
    border-radius: $border-radius-4px;
    height: $height-8px;
    width: 50%;

    &--in-completed {
      background-color: $color-gray-100;
    }

    &--completed {
      background-color: $color-primary-600;

      &-full {
        background-color: $color-primary-600;
        width: 100%;
      }
    }
  }
}
