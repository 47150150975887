@import 'styles/utils';

.nav-header {
  &__navigation--list,
  &__navigation-actions--list {
    align-items: center;
    display: flex;
    gap: $spacing-32px;
  }

  &__navigation--item {
    list-style: none;

    a.active {
      color: $neon-green;
    }

    &:hover {
      color: $neon-green;
    }

    &-disable {
      color: $posh-sorrel;

      &:hover {
        color: $posh-sorrel;
      }
    }
  }

  &__navigation--link {
    color: inherit;
    cursor: pointer;
    font-size: $font-16px;
    font-style: normal;
    font-weight: $font-weight-600;
    line-height: $line-height-24px;
    text-decoration: none;

    &-icon {
      display: flex;
      gap: $spacing-8px;

      &:hover {
        svg {
          fill: $neon-green;
        }
      }
    }
  }
}

.drop-down {
  &__content {
    background-color: $white;
    border: 1px solid $color-gray-200;
    border-radius: 8px;
    box-shadow: $dropdown-box-shadow;
    display: flex;
    position: absolute;
    top: calc($spacing-32px * 2 + $spacing-2px);
    width: 17.5rem;
    z-index: 20;

    &--menu {
      padding: $spacing-2px $spacing-6px;

      &-item {
        color: $color-gray-900;
        text-decoration: none;

        &:hover {
          color: $neon-green;
        }
      }

      &-disable {
        color: $posh-sorrel;

        &:hover {
          color: $posh-sorrel;
        }
      }

      li {
        list-style: none;
        padding: $spacing-10px $spacing-10px $spacing-10px $spacing-8px;
      }
    }
  }
}
