@import 'styles/utils';

.reject-bid {
  &__delete-button {
    color: $white;
    background: $danger;
    border: 1px solid $tertiary-accent-light;
    border-radius: $border-radius-8px;
    box-shadow: $spacing-none $spacing-1px $spacing-2px $spacing-none $light-box-shadow;
    cursor: pointer;
    font-size: $font-16px;
    font-weight: $font-weight-600;
    line-height: $line-height-24px;
    padding: $spacing-10px $spacing-18px;
    width: 100%;
    margin-top: $spacing-10px;

    &--disabled {
      background: $white;
      border: 1px solid $color-gray-200;
      color: $color-gray-300;
      display: flex;
      align-items: center;
      gap: $spacing-5px;
      justify-content: center;
    }
  }
}
