@import 'styles/utils';

.contract-documents {
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-12px;
  margin-top: $spacing-16px;

  &__header {
    background-color: $color-gray-25;
    border-bottom: 1px solid $color-gray-200;
    border-radius: $border-radius-12px $border-radius-12px 0 0;
    color: $color-gray-600;
    font-size: $font-12px;
    font-weight: $font-weight-500;
    line-height: $line-height-20px;
    padding: $spacing-12px $spacing-24px;
  }

  &__form {
    padding: $spacing-24px;
    padding-top: 0;

    &--delete-doc {
      margin-top: -3px;
    }
  }
}
