@import 'styles/utils';

.contact-admin-modal-success {
  align-items: center;
  display: flex;
  flex-direction: column;

  &__icon {
    background-color: $color-primary-100;
    border-radius: 50%;
    padding: $spacing-14px;
  }

  &__message {
    color: $color-gray-700;
    font-size: $font-18px;
    line-height: $line-height-28px;
    text-align: center;
  }
}
