// border
.border-b-none {
  border-bottom: none;
}

.border-t-1 {
  border-top: 1px;
}

.border-y-1 {
  border-bottom: 1px;
  border-top: 1px;
}

.border-b-1 {
  border-bottom: 1px;
}

.border-gray-200 {
  border-color: $color-gray-200;
}

.border-y-solid {
  border-bottom-style: solid;
  border-top-style: solid;
}

.border-t-solid {
  border-top-style: solid;
}

.border-b-solid {
  border-bottom-style: solid;
}

.border-filter {
  border-radius: 0.75rem;
}

// color
.card-link-green {
  color: $color-primary-800;
}

.filters-green {
  background-color: $color-primary-100;
}

// text
.decoration-none {
  color: $color-gray-900;
  text-decoration: none;
}

.text-capitalize {
  text-transform: capitalize;
}

// width
.width-100 {
  width: 100%;
}

.width-30 {
  width: 30%;
}

.width-70 {
  width: 70%;
}

.default-block {
  width: 90%;
  margin: 2rem auto;
}


//position

.position-relative {
  position: relative;
}
