@import 'styles/utils';

.listings {
  margin: auto;
  padding: $spacing-32px 0;
  width: 60%;

  &-wrapper {
    background-color: $color-gray-50;
    height: 90vh;
    overflow: auto;
  }

  .rtabs-tab-container {
    .rtabs {
      &__tab {
        color: $color-gray-900;

        &--selected {
          color: $neon-green;
        }
      }
    }
  }

  .rtabs {
    &__inkbar {
      background-color: $neon-green;
    }

    &__container {
      .product-card {
        margin-top: $spacing-16px;
      }
    }
  }

  &__empty-content {
    text-align: center;

    &--create-offer-button {
      background: $color-primary-600;
      border: 1px solid $color-primary-600;
      border-radius: $spacing-8px;
      box-shadow: $light-box-shadow;
      color: $color-gray-900;
      font-size: $spacing-16px;
      font-weight: $font-weight-600;
      line-height: $spacing-24px;
    }
  }
}
