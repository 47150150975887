@import 'styles/utils';

.listing-management-table {

  &__product-type {
    display: flex;
    flex-wrap: nowrap;

    padding: $spacing-4px;
    padding-top: $spacing-12px;
    padding-left: $spacing-18px;
    border-radius: $border-radius-8px;

    &__kernel {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      font-size: $font-16px;
      font-weight: 500;
    }

    &__dried_nut_in_shell {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      font-size: $font-16px;
      font-weight: 500;
    }
  }

  &--status {
    .table__field-value {
      background-color: $color-blue-50;
      border-radius: $border-radius-10px;
      color: $color-blue-700;
      display: inline-block;
      font-weight: $font-weight-500;
      padding: $spacing-2px $spacing-8px;
      text-transform: capitalize;
    }

    &-green-text {
      .table__field-value {
        background-color: $color-primary-100;
        color: $dark-green;
      }
    }

    &-gray-text {
      .table__field-value {
        background-color: $color-gray-200;
        color: $color-gray-900;
      }
    }
  }
}
