@import 'styles/utils';

.listing-details-header {
  border: 1px solid $color-gray-200;
  border-top-left-radius: $border-radius-10px;
  color: $maastricht-blue;
  font-size: $font-30px;
  font-weight: $font-weight-600;
  padding: $spacing-24px;
  text-transform: capitalize;

  &__divider {
    padding: $spacing-none $spacing-16px;
  }
}
