.signup {
  width: 100%;

  &__left-container {
    height: 100vh;
    position: fixed;
    width: 50%;
  }

  &__right-container {
    height: 100%;
    margin-left: 50%;
    width: 50%;
  }
}
