@import 'styles/utils';

.batch-details {
  border-top: 1px solid $color-gray-200;

  &__radio-block {
    display: flex;
    flex-direction: column;
  }

  &__radio {
    &--options {
      padding: $spacing-12px 0;

      .rcl-radio__label {
        font-size: $font-16px;
      }

      &--access-to-pasteurization {
        display: flex;
        gap: $spacing-16px;
        flex-direction: column;
        &--block {
          display: flex;
          flex-direction: row;
        }
      }
    }
  }

  &__description {
    align-items: flex-start;
    background-color: $color-gray-25;
    border: 1px solid $color-gray-200;
    border-radius: $border-radius-12px;
    display: flex;
    gap: $spacing-16px;
    padding: $spacing-12px $spacing-24px;

    svg {
      fill: $color-gray-700;
      margin-top: $spacing-4px;
    }

    &--text {
      color: $color-gray-600;
      font-size: $font-16px;
      line-height: $line-height-24px;

      p:first-child {
        margin-top: 0;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__hint-text {
    background-color: $color-warning-25;
    border: 1px solid $color-warning-200;
    margin-top: $spacing-16px;

    svg {
      fill: $color-warning-600;
    }
  }
}
