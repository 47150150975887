@import 'styles/utils';

.unauthorized {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 95vh;
  justify-content: center;
  position: relative;

  &__centered-content {
    left: 50%;
    position: absolute;
    text-align: center;
    top: 40%;
    transform: translate(-50%, -50%);

    &--logo {
      height: 250px;
      width: 250px;
    }

    &--title {
      animation: scaleAnimation 1s ease-in-out infinite;
      font-size: $font-44px;
      font-weight: $font-weight-500;
      padding: $spacing-20px $spacing-none;
    }

    &--sub-text {
      font-size: $font-16px;
      padding: $spacing-20px $spacing-none;
    }

    &--button {
      background: $neon-green;
      border: 1px solid $neon-green;
      border-radius: $border-radius-8px;
      color: $maastricht-blue;
      cursor: pointer;
      font-size: $font-14px;
      font-weight: $font-weight-600;
      margin-top: $spacing-20px;
      padding: $spacing-10px $spacing-16px;
    }
  }

  &__footer {
    margin-top: auto;
    padding: $spacing-10px;
    text-align: center;
  }
}

@keyframes scaleAnimation {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}
