@import 'styles/utils';

.unauthorized-sign-up {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  margin: auto;
  width: 75%;

  &__text {
    color: $color-gray-700;
    font-size: $font-16px;
    font-weight: $font-weight-500;
    line-height: $line-height-28px;
    margin-top: $spacing-16px;
    text-align: center;
  }

  &__button {
    background: $neon-green;
    border: 1px solid $neon-green;
    border-radius: $border-radius-8px;
    color: $maastricht-blue;
    cursor: pointer;
    font-size: $font-14px;
    font-weight: $font-weight-600;
    margin-top: $spacing-20px;
    padding: $spacing-10px $spacing-16px;
  }
}
