@import 'styles/utils';

.test-results-table {
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-12px;
  margin-top: $spacing-16px;

  .table__head > tr > th {
    border-radius: $border-radius-12px;
    color: $color-gray-600;
  }

  .table__field {
    border-color: $color-gray-200;
    color: $maastricht-blue;
  }

  .table__field-value {
    .select-box__tooltip-wrapper {
      left: 290px;
      right: 0;
    }
  }

  .table__field:first-child {
    font-weight: $font-weight-500;
  }

  .table__field:nth-child(3) {
    width: 30%;
  }

  .table__field:last-child {
    min-width: 150px;
  }

  .table__row {
    border-bottom: 0;
  }

  &__select-box {
    width: 275px;
  }
}
