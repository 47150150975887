@import 'styles/utils';

.flag-select {
  width: 100%;

  .ReactFlagsSelect-module {
    &_selectValue {
      &__152eS {
        color: $blueish-black;
      }
    }

    &_selectBtn {
      &__19wW7 {
        &::after {
          background: url($dropdown-icon);
          border: 0;
          height: $height-20px;
          width: $width-20px;
        }
      }
    }
  }

  &__title {
    color: $color-gray-700;
    font-size: $font-14px;
    font-weight: $font-weight-500;
    line-height: $line-height-20px;
    margin-bottom: $spacing-6px;
    margin-top: $spacing-none;

    &--required {
      color: $color-error-600;
      margin-left: $spacing-5px;
    }
  }

  &__dropdown {
    border: 1px solid $color-gray-300 !important;
    border-radius: $border-radius-8px !important;
    color: $color-gray-500 !important;
    font-size: $font-16px !important;
    font-weight: $font-weight-400;
    height: 2.5rem;
    margin-top: 6px;

    &--error {
      border: 1px solid $danger !important;
    }
  }
}
