@import 'styles/utils';

.admin-offer-order-details {
  background-color: $color-gray-25;

  .empty-content {
    height: 100vh;
  }

  &__header {
    background-color: $white;
    border-bottom: 1px solid $color-gray-200;
    box-shadow: $spacing-none $spacing-1px $spacing-2px $spacing-none $light-box-shadow;
  }

  &__body {
    margin: auto;
    padding: $spacing-24px $spacing-32px;
    width: 85%;
  }
}
