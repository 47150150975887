@import 'styles/utils';

.contract-documents {
  background-color: $white;
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-12px;
  box-shadow: $protals-form-shadow;
  font-weight: $font-weight-600;
  line-height: $line-height-20px;

  &__title {
    border-bottom: 1px solid $color-gray-200;
    color: $color-gray-900;
    font-size: $font-24px;
    font-weight: $font-weight-600;
    line-height: $line-height-32px;
    margin: $spacing-none;
    padding: $spacing-32px $spacing-24px $spacing-24px;
  }

  &-file {
    font-size: $font-14px;
    padding: $spacing-32px $spacing-24px;

    &__title {
      border-bottom: 1px solid $color-gray-100;
      color: $color-gray-500;
      margin-bottom: $spacing-20px;
      padding-bottom: $spacing-12px;
    }

    &__signed-contract {
      display: flex;
      justify-content: space-between;

      &--title {
        color: $color-gray-700;
        display: flex;
        font-weight: $font-weight-500;
        gap: $spacing-8px;
      }

      &--icon {
        align-self: center;
      }
    }
  }
}
