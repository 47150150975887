@import 'styles/utils';

.details-body {
  box-shadow: $spacing-none $spacing-1px $spacing-2px $spacing-none $light-box-shadow;
  flex: 2.4;

  &--buyer-seller {
    flex: 3.4;
  }

  .listing-details-author {
    color: #00162d;
    font-size: 1.5rem;
    font-weight: 600;
    border: 1px solid #eaecf0;
    padding-left: 1rem;
    border-right: none;
    text-transform: capitalize;
  }
}
