@import 'styles/utils';

.affiliations {
  .admin-portal__table--container {
    background-color: $white;
    margin-bottom: auto;
    margin-left: 8rem;
    width: 85%;
  }

  &__table-container {
    background-color: $color-gray-50;
    display: flex;
    height: 60vh;

    &--verified {
      height: 74vh;
    }
  }
}
