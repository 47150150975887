@import 'styles/utils';

.aggregate-data-filter {
  background-color: $base-white;
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-12px;
  max-height: 100vh;
  overflow-y: auto;
  position: sticky;
  top: 0;

  &__box {
    border-bottom: 1px solid $color-gray-200;
    padding: $spacing-24px;

    &--small {
      padding: $spacing-24px;
      padding-top: 0;
    }

    &--title {
      color: $color-gray-900;
      font-size: $font-18px;
      font-weight: $font-weight-600;
      line-height: $line-height-28px;
      margin: $spacing-none;

      &-large {
        font-size: $font-24px;
        line-height: $line-height-32px;
      }

      &-block {
        justify-content: space-between;
        width: 100%;
      }

      &-small {
        font-size: $font-14px;
        line-height: $line-height-16px;
      }
    }

    &--reset-button {
      background-color: $base-white;
      border: none;
      color: $color-gray-700;
      font-size: $font-14px;
      font-weight: $font-weight-400;
      line-height: $line-height-20px;
      padding: $spacing-none;

      &:hover {
        background-color: $base-white;
        border: none;
      }
    }

    &--radio-options {
      .rcl-radio {
        &-circle {
          border: 1px solid $color-gray-300;
        }

        &__label {
          color: $color-gray-600;
          font-size: $font-14px;
          font-weight: $font-weight-400;
          line-height: $line-height-20px;
        }
      }
    }

    &--range-slider {
      .input {
        &__field-holder {
          border-radius: $border-radius-8px;
        }

        &__field {
          &::placeholder {
            color: $color-gray-500;
            font-size: $font-16px;
          }
        }
      }
    }
  }
}
