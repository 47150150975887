@import 'styles/utils';

.action-confirmation-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: $spacing-24px;
  width: 350px;

  &__icon {
    background-color: $color-warning-100;
    border-radius: 50%;
    margin-bottom: $spacing-16px;
    padding: $spacing-12px;

    svg {
      fill: $color-warning-600;
    }
  }

  &__title {
    color: $color-gray-900;
    font-size: $font-18px;
    font-weight: $font-weight-600;
    line-height: $line-height-28px;
    margin: 0;
    margin-bottom: $spacing-4px;
  }

  &__description {
    color: $color-gray-600;
    line-height: $line-height-20px;
    margin: 0;
    text-align: center;
  }

  &__buttons-container {
    display: flex;
    gap: $spacing-12px;
    justify-content: space-between;
    margin-top: $spacing-32px;

    &--button {
      border-radius: $border-radius-8px;
      font-weight: $font-weight-600;
      padding: $spacing-10px $spacing-18px;
      width: 170px;
    }
  }
}
