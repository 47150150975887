@import 'styles/utils';

@mixin size-based-changes(
  $font-size,
  $clear-icon-font-size,
  $height,
  $padding,
  $calendar-icon-right,
  $clear-icon-right,
  $tooltip-bottom,
  $tooltip-right,
  $label-size: $font-14px
) {
  .date-picker {
    &__label {
      font-size: $label-size;
    }

    &__tooltip-wrapper {
      bottom: $tooltip-bottom;
      right: $tooltip-right;

      &--error-with-clear-btn {
        right: calc(#{$tooltip-right} + 28px);
      }
    }
  }

  .SingleDatePickerInput {
    width: 100%;

    .DateInput_input {
      border-radius: 8px;
      color: $blueish-black;
      font-size: $font-size;
      height: $height;
      line-height: $line-height-24px;
      padding: $padding;

      &::placeholder {
        font-size: $font-size;
        font-weight: $font-weight-400;
      }
    }

    &_calendarIcon {
      font-size: $font-size;
      right: $calendar-icon-right;
    }

    &_clearDate {
      font-size: $clear-icon-font-size;
      right: $clear-icon-right;
    }
  }
}

.date-picker {
  position: relative;

  input {
    font-family: 'Inter', sans-serif;
    font-weight: $font-weight-400;
  }

  &__label {
    align-items: center;
    color: $color-gray-700;
    display: flex;
    font-weight: $font-weight-500;
    margin: 0 0 6px;

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &--required {
      &::after {
        color: $color-error-600;
        content: '*';
        margin-left: 5px;
      }
    }
  }

  &__call-back-btn {
    font-size: $font-14px;
    height: 100%;
    line-height: $line-height-24px;
    margin-bottom: 20px;
  }

  &__tooltip-wrapper {
    position: absolute;

    > .rcl-popover__parent {
      display: block;
      font-size: 16px;
    }

    .icon--tertiary svg {
      font-size: 20px;
    }
  }

  &__tooltip {
    &.rcl-tooltip.rcl-popover {
      padding: 6px 10px;

      .rcl-popover__content {
        font-size: 12px;
      }

      .rcl-popover__tip {
        &--bottom-left,
        &--top-left {
          left: $input-tooltip-tip-offset;
        }

        &--bottom-right,
        &--top-right {
          right: $input-tooltip-tip-offset;
        }

        &--left-top,
        &--right-top {
          top: calc(#{$input-tooltip-tip-offset} * 2);
        }

        &--left-bottom,
        &--right-bottom {
          bottom: calc(#{$input-tooltip-tip-offset} * 2);
        }
      }
    }
  }

  .SingleDatePickerInput {
    border: 1px solid $color-gray-300;
    border-radius: 8px;
    padding-right: 0;
    position: relative;
    width: 312px;

    &_calendarIcon,
    &_clearDate {
      align-items: center;
      display: flex;
      font-size: 18px;
      justify-content: center;
      margin: 0;
      outline: none;
      padding: 0;
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);

      svg {
        display: block;
        fill: $posh-sorrel;
        height: 1em;
        stroke-width: 0;
        width: 1em;
      }
    }

    &_clearDate {
      right: 37px;
    }

    &_clearDate:hover {
      background: none;
      border-radius: 0;
    }

    .DateInput {
      border-radius: 8px;
      width: 100%;

      &_fangStroke,
      &_fang {
        display: none;
      }

      &_input {
        border: 0 solid $blueish-black-16;
        border-radius: 4px;
        box-sizing: border-box;
        color: $blueish-black;
        font-size: 1rem;
        height: 2.5rem;
        outline: 0;
        padding: 10px 12px;
        width: 100%;

        &__focused {
          border-color: $blueish-black;
          width: 100%;
        }
      }

      &_input:hover,
      &_input:focus {
        background: $white;
        border-color: $blueish-black;
      }

      &_input::placeholder {
        color: $color-gray-500;
        font-size: 1rem;
      }

      &_input:disabled {
        font-style: normal;
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  .SingleDatePicker {
    width: 100%;
  }

  &--tiny {
    @include size-based-changes($font-14px, 0.75rem, 2rem, 7px 8px, 8px, 30px, 8px, 28px);
  }

  &--small {
    @include size-based-changes($font-16px, 0.8rem, 2.5rem, 10px 12px, 12px, 40px, 12px, 35px);
  }

  &--large {
    @include size-based-changes($font-20px, 1rem, 3rem, 11px 16px, 16px, 50px, 15px, 45px);
  }

  &--huge {
    @include size-based-changes($font-24px, 1.25rem, 3.5rem, 12px 20px, 20px, 62px, 19px, 50px);
  }

  &--full-width {
    width: 100%;

    .SingleDatePicker,
    .SingleDatePickerInput {
      width: 100%;
    }
  }

  &--error {
    .SingleDatePickerInput .DateInput {
      &_input,
      &__focused,
      &_input:hover,
      &_input:focus {
        border: 1px solid $normal-red;
      }
    }
  }

  &--no-nav-buttons {
    .SingleDatePicker_picker {
      .DayPickerNavigation {
        display: none;
      }
    }
  }

  &__dropdown {
    &-navigation {
      display: flex;
      flex-wrap: wrap;

      &-select-box {
        flex-grow: 1;

        &:first-of-type {
          margin: 0 5px 0 0;
        }

        &:last-of-type {
          margin: 0 0 0 5px;
        }

        .select-box__menu {
          &-list {
            max-height: 200px;
          }
        }
      }
    }
  }
}

.SingleDatePicker_picker {
  background-color: transparent;
  z-index: 2;

  .DayPicker {
    border: 1px solid $periwinkle-gray;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba($blueish-black, 0.16);
    color: $blueish-black;

    &_weekHeader {
      margin: 22px 0 0;
      z-index: 1;

      &_li {
        color: $primary-text;
        display: inline-block;
        text-align: center;
        width: 39px;
      }
    }

    .CalendarMonth_caption {
      padding-bottom: 56px;
    }

    .CalendarMonthGrid {
      background: transparent;
      z-index: 2;
    }

    .CalendarMonth {
      background: transparent;
    }

    &_transitionContainer__horizontal {
      max-height: 429px !important;
    }
  }

  .DayPickerNavigation {
    display: flex;
    justify-content: space-between;
    padding: 0 22px 22px;

    &_button {
      align-items: center;
      display: inline-flex;
      height: 24px;
      justify-content: center;
      padding: 3px;
      width: 24px;

      &:hover {
        background-color: rgba($blueish-black, 0.16);
        border-radius: 3px;
      }
    }
  }

  .CalendarDay {
    border: 1px solid rgba($blueish-black, 0.16);

    &:hover {
      background: rgba($blueish-black, 0.16);
      border: 1px solid rgba($blueish-black, 0.24);
    }

    &__selected {
      background: $blueish-black;
      border: 1px solid rgba($blueish-black, 0.16);

      &:hover {
        background: $blueish-black;
        border: 1px solid rgba($blueish-black, 0.16);
      }
    }
  }
}
