@import 'styles/utils';

.product-details-form {
  background-color: $color-gray-50;
  width: 100%;

  &__fields {
    background-color: $base-white;
    border: 1px solid $color-gray-200;
    border-radius: $border-radius-12px;
    padding: $spacing-24px;
  }

  &__title {
    border-bottom: 1px solid $color-gray-200;
    border-top-left-radius: $border-radius-12px;
    border-top-right-radius: $border-radius-12px;
    color: $color-gray-900;
    font-size: $font-24px;
    font-weight: $font-weight-600;
    line-height: $line-height-32px;
    padding: $spacing-8px $spacing-none $spacing-24px;
  }

  &__section-title {
    border-bottom: 1px solid $color-gray-100;
    color: $color-gray-500;
    font-size: $font-14px;
    font-weight: $font-weight-600;
    line-height: $line-height-20px;
    margin-bottom: $spacing-none;
    padding-bottom: $spacing-12px;
  }

  &__offer-volume,
  &__price-type,
  &__boxes-quantity,
  &__offer-price {
    width: 100%;
  }

  &__logisitcs {
    display: grid;
    grid-gap: $spacing-20px;
    grid-template-columns: 1fr 1fr;
  }

  &__religion {
    display: flex;
    flex-direction: column;
    justify-items: center;

    &-container {
      display: flex;
      flex-direction: column;
      justify-items: center;
      gap: 12px;
    }

    &-label {
      font-weight: $font-weight-500;
      font-size: $font-14px;
      color: $color-gray-500;
    }
  }
}
