@import 'styles/utils';

.consignment-details {
  border: 1px solid $color-gray-200;
  padding: $spacing-24px;

  &__title {
    color: $color-gray-900;
    font-size: $font-18px;
    font-weight: $font-weight-600;
    line-height: $line-height-28px;
    margin-bottom: $spacing-20px;
  }
}
