@import 'styles/utils';

.rcl-avatar {
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;

  > * {
    box-sizing: border-box;
  }

  &__content {
    align-items: center;
    border-radius: 50%;
    color: $white;
    display: flex;
    justify-content: center;
    position: relative;

    &-image {
      border-radius: 50%;
      display: inline-block;
      height: inherit;
      object-fit: cover;
      object-position: center;
      width: inherit;
    }

    &-badge {
      box-shadow: 0 0 0 2px $white;
      position: absolute;
    }

    &--tiny {
      font-size: 0.75rem;
      height: 1.5rem;
      width: 1.5rem;

      .rcl-avatar__content-badge {
        bottom: -4%;
        right: -4%;
      }

      .badge {
        height: 8px;
        width: 8px;
      }
    }

    &--small {
      font-size: 0.875rem;
      height: 2rem;
      width: 2rem;

      .rcl-avatar__content-badge {
        bottom: 0;
        right: 0;
      }

      .badge {
        height: 10px;
        width: 10px;
      }
    }

    &--normal {
      font-size: 1rem;
      height: 2.5rem;
      width: 2.5rem;

      .rcl-avatar__content-badge {
        bottom: 0;
        right: 0;
      }

      .badge {
        height: 10px;
        width: 10px;
      }
    }

    &--large {
      font-size: 1.5rem;
      height: 3.5rem;
      width: 3.5rem;

      .rcl-avatar__content-badge {
        bottom: 4%;
        right: 4%;
      }
    }

    &--huge {
      font-size: 2rem;
      height: 5rem;
      width: 5rem;

      .rcl-avatar__content-badge {
        bottom: 7%;
        right: 7%;
      }
    }

    &--xl {
      font-size: 3rem;
      height: 7.5rem;
      width: 7.5rem;

      .rcl-avatar__content-badge {
        bottom: 9%;
        right: 9%;
      }
    }

    &--xxl {
      font-size: 3.5rem;
      height: 8.5rem;
      width: 8.5rem;

      .rcl-avatar__content-badge {
        bottom: 10%;
        right: 10%;
      }
    }

    &--with-img {
      background: $posh-sorrel;

      .icon svg {
        fill: $white;
      }
    }

    &--leaky-rust-light {
      background-color: $leaky-rust-light;
    }

    &--bright-leaf {
      background-color: $color-primary-100;
      color: $color-primary-600;
      font-weight: $font-weight-600;
    }

    &--comestic-chartreuse {
      background-color: $comestic-chartreuse;
    }

    &--committed-tawny {
      background-color: $committed-tawny;
    }

    &--fantastic-royal {
      background-color: $fantastic-royal;
    }

    &--lofty-ashen {
      background-color: $lofty-ashen;
    }

    &--losing-rust {
      background-color: $losing-rust;
    }

    &--outer-bronze {
      background-color: $outer-bronze;
    }

    &--predictable-shark {
      background-color: $predictable-shark;
    }

    &--shiny-cobalt {
      background-color: $shiny-cobalt;
    }

    &--unruly-chartreuse {
      background-color: $unruly-chartreuse;
    }

    &--buyer-avatar {
      background-color: $color-blue-50;
      color: $color-blue-700;
    }

    &--seller-avatar {
      background-color: $color-primary-100;
      color: $color-primary-600;
    }

    &--affiliate-avatar {
      background-color: $color-gray-100;
      color: $color-gray-600;
    }
  }

  &__text {
    color: $primary-text;

    &-name {
      margin-left: 10px;

      &--tiny {
        font-size: 0.875rem;
      }

      &--normal {
        font-size: 1.5rem;
      }

      &--large {
        font-size: 2rem;
      }

      &--huge {
        font-size: 3rem;
      }

      &--xl {
        font-size: 3.5rem;
      }

      &--xxl {
        font-size: 4rem;
      }
    }

    &-description {
      color: $cool-gray-66;
      margin-left: 10px;
      margin-top: 2px;

      &--tiny {
        font-size: 0.75rem;
      }

      &--normal {
        font-size: 0.875rem;
      }

      &--large {
        font-size: 1.25rem;
      }

      &--huge {
        font-size: 1.5rem;
      }

      &--xl {
        font-size: 2rem;
      }

      &--xxl {
        font-size: 3.5rem;
      }
    }
  }
}
