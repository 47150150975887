@import 'styles/utils';

.contract-details-header {
  margin: auto;
  padding: $spacing-32px $spacing-none;
  width: 90%;

  &-title {
    color: $color-gray-900;
    font-size: $font-30px;
    font-weight: $font-weight-600;
    line-height: $line-height-38px;
    padding-bottom: $spacing-4px;
  }

  &-title-and-button {
    justify-content: space-between;
  }

  &-information {
    color: $color-gray-600;
    display: flex;
    font-size: $font-14px;
    font-weight: $font-weight-500;
    gap: $spacing-16px;
    line-height: $line-height-20px;

    &__value {
      font-weight: $font-weight-400;
      padding-left: $spacing-4px;

      &--green-text {
        color: $color-primary-600;
      }
    }
  }
}
