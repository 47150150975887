@import 'styles/utils.scss';

.form-footer {
  &__content-divider {
    margin: $spacing-20px $spacing-none $spacing-16px $spacing-none;

    &--box {
      background-color: $color-gray-200;
      height: 1px;
      width: 100%;
    }

    &--text {
      font-size: $font-14px;
      font-weight: $font-weight-500;
      line-height: $line-height-20px;
      margin: $spacing-none $spacing-8px;
    }
  }

  &__login-message {
    color: $color-gray-600;
    font-size: $font-14px;
    font-weight: $font-weight-400;
    line-height: $line-height-20px;
    margin: $spacing-none;
    text-align: center;

    &--redirect {
      color: $color-primary-700;
      font-size: $font-14px;
      font-weight: $font-weight-600;
      line-height: $line-height-20px;
      margin-left: $spacing-4px;
      text-decoration: none;
    }
  }
}
