@import 'styles/utils';

.food-safety-offer-form {
  background-color: $white;
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-12px;

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: $spacing-24px;
    padding-top: $spacing-32px;

    &--title {
      color: $color-gray-900;
      font-size: $font-24px;
      font-weight: $font-weight-600;
      line-height: $line-height-32px;
      margin: 0;
    }

    .select-box__placeholder-text {
      color: $color-gray-700;
      font-weight: $font-weight-600;
    }
  }

  &__batch-select-box {
    width: 450px;
  }
}
