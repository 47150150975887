@import 'styles/utils';

.details-information-table {
  border: 1px solid $color-gray-200;
  padding: 0;

  .table__caption {
    h2 {
      font-size: $font-14px;
      font-weight: $font-weight-600;
      margin: $spacing-none;
      padding: $spacing-12px $spacing-none;
      padding-left: $spacing-24px;
    }
  }

  &__caption {
    background: $color-gray-50;
    border-bottom: $spacing-1px solid $color-gray-200;
    color: $color-gray-500;
    line-height: $line-height-20px;
  }

  &__data {
    &--bold {
      font-size: $font-16px;
      font-weight: $font-weight-500;
    }
  }

  .table__head > tr > th {
    color: $color-gray-600;
    font-size: $font-12px;
    font-weight: $font-weight-500;
    line-height: $line-height-18px;
  }
}
