@import 'styles/utils';

.modal {
  &__title {
    display: flex;
    flex-grow: 1;
    font-size: $font-20px;
    font-weight: $font-weight-500;

    &--left {
      justify-content: flex-start;
    }

    &--center {
      justify-content: center;
    }

    &--right {
      justify-content: flex-end;
    }
  }

  .icon {
    cursor: pointer;
  }
}
