@import 'styles/utils';

.aggregated-csv-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: $spacing-48px $spacing-96px;
  width: 700px;

  .icon {
    cursor: auto;
  }

  &__icon {
    background-color: $color-primary-100;
    border-radius: 50%;
    padding: $spacing-48px;

    &--error {
      background-color: $color-error-100;
    }
  }

  &__title {
    font-size: $font-20px;
  }

  &__description {
    font-size: $font-16px;
    margin-bottom: $spacing-24px;

    &--error {
      color: $danger;
    }
  }

  &__errors {
    color: $danger;
    list-style: decimal;
    overflow-y: auto;
    width: 100%;
  }

  .choose-file {
    &__label {
      font-size: $font-16px;
    }
  }

  &__form {
    width: 100%;
  }

  &__import-type {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;

    padding: $spacing-4px;
    background-color: $color-gray-100;
    border-radius: $border-radius-8px;
    border: 1px solid $color-gray-200;

    &__kernel {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      width: 100%;
      font-size: $font-16px;
      font-weight: 500;
    }

    &__dried_nut_in_shell {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      width: 100%;
      font-size: $font-16px;
      font-weight: 500;
    }
  }
}
