@import 'styles/utils';

.listing-details {
  background-color: $color-gray-50;
  height: 100%;

  &--nothing-found {
    height: 91vh;
  }

  &-container {
    margin: auto;
    width: 82%;

    &__breadcrumb {
      padding: $spacing-16px $spacing-none;
    }

    &__body {
      padding-bottom: $spacing-32px;
    }

    &__empty {
      height: 100%;
      padding-top: $spacing-100px;
    }
  }
}
