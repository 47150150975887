@import 'styles/variables';

.trading-card {
  background-color: $color-gray-50;
  border-left: 4px solid $neon-green;
  padding: $spacing-16px $spacing-24px;

  &__heading {
    font-size: $font-20px;
    font-weight: $font-weight-600;
    line-height: $line-height-30px;
    margin: $spacing-none;
  }

  &__body {
    color: $color-gray-600;
    font-size: $font-16px;
    line-height: $line-height-24px;
    margin: $spacing-12px $spacing-none;
  }

  &__list-items {
    color: $color-gray-600;
    font-size: $font-16px;
    line-height: $line-height-24px;
    margin: $spacing-12px $spacing-none;
    padding-left: $spacing-16px;
  }
}
