// --- RCL Component ---
@import 'styles/utils';

$line-color-primary-light: rgba($primary-accent, 0.24);
$line-color-secondary-light: rgba($secondary-accent, 0.24);
$line-color-tertiary-light: rgba($tertiary-accent, 0.24);

.line-chart {
  --green: #{$dark-green};
  --primary: #{$primary-accent};
  --primary-light: #{$line-color-primary-light};
  --red-500: #{$color-error-500};
  --secondary: #{$secondary-accent};
  --secondary-light: #{$line-color-secondary-light};
  --tertiary: #{$tertiary-accent};
  --tertiary-light: #{$line-color-tertiary-light};

  &__custom-tooltip {
    background: $white;
    border: 1px solid $color-gray-300;
    border-radius: $border-radius-8px;
    color: $color-gray-700;
    padding: $spacing-16px;

    &--label {
      font-weight: $font-weight-600;
      margin: 0;
    }

    &--legend {
      margin-right: $spacing-4px;
    }
  }

  &__chart {
    .recharts-tooltip-cursor {
      fill: none !important; // Reason to use `!important`: inline style coming from rechart library
    }

    .recharts-legend-wrapper {
      .recharts-legend-item {
        &:last-of-type {
          margin-right: 0 !important; // Reason to use `!important`: inline style coming from rechart library
        }

        &-text {
          color: rgba(
            $secondary-accent,
            0.8
          ) !important; // Reason to use `!important`: inline style coming from rechart library
          font-size: 14px;
        }
      }

      .recharts-surface {
        margin-right: 5px !important; // Reason to use `!important`: inline style coming from rechart library
      }
    }

    .recharts-cartesian-grid-horizontal,
    .recharts-cartesian-grid-vertical,
    .recharts-cartesian-axis {
      > line {
        stroke: rgba($secondary-accent, 0.04);
      }
    }

    .recharts-cartesian-axis {
      &-tick {
        > line {
          display: none;
        }

        > text {
          fill: $secondary-accent;
          font-size: 12px;
        }
      }
    }

    .recharts-xAxis {
      .recharts-cartesian-axis-tick {
        > line {
          display: none;
        }

        > text {
          font-size: 12px;
        }
      }
    }
  }
}
