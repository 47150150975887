@import 'styles/utils';

.contract-details-header-content {
  .nav-header {
    border-bottom: 1px solid $color-gray-200;
  }

  &__pdf-details {
    background: $color-gray-50;
    border: 1px solid $color-gray-200;
    display: flex;
    height: 100%;
    justify-content: center;
    margin-top: $spacing-32px;
    padding: $spacing-24px;

    &--pdf-viewer {
      height: 95vh;
      width: 84%;
    }
  }

  &__no-pdf-card {
    align-items: center;
    display: flex;
    height: 71vh;
  }
}
