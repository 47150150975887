@import '~reflex-grid/scss/reflex';
@import 'styles/flex';
@import 'styles/spacing';
@import 'styles/custom-classes';

.app {
  &__main {
    font-family: 'Inter', sans-serif;
  }
}

@media only screen and (max-width: $large-desktop) {
  .app__main {
    overflow-x: scroll;
  }
}

@media only screen and (max-width: 1050px) {
  .nav-header {
    overflow-x: scroll;
  }
}
