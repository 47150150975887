@import 'styles/utils';

.admin-portal {
  &__table {
    &--container {
      border: 1px solid $color-gray-200;
      border-radius: $border-radius-12px;
      box-shadow: $table-box-shadow;
      margin-top: $spacing-32px;
      width: 95%;

      .rtabs__container {
        padding: $spacing-none;

        .rtabs__tabs {
          border-bottom: 1px solid $color-gray-200;
          font-weight: $font-weight-600;
          padding: $spacing-12px $spacing-16px;

          .rtabs-tab-container {
            margin-right: $spacing-8px;

            .rtabs__tab {
              padding: $spacing-8px $spacing-12px;
            }

            .rtabs__tab--selected {
              background-color: $color-primary-700;
              border-radius: $border-radius-6px;
              color: $white;
              text-align: center;
            }
          }
        }

        .rtabs__inkbar-wrapper {
          display: none;
        }

        .rtabs__panel {
          padding-top: $spacing-2px;
          padding-bottom: $spacing-none;
        }
      }
    }

    &.table {
      color: $color-gray-600;

      .table__table {
        .table__body {
          .table__row:last-child {
            border: 0;
          }
        }
      }
    }

    &--caption {
      border-bottom: 1px solid $color-gray-200;
      color: $color-gray-900;
      font-size: $font-18px;
      font-weight: $font-weight-600;
      margin: $spacing-none;
      padding: $spacing-20px $spacing-24px;
    }

    &--tab {
      border-bottom: 1px solid $color-gray-200;
      color: $color-gray-900;
      font-size: $font-18px;
      font-weight: $font-weight-600;
      padding: $spacing-12px $spacing-24px;
      margin-right: $spacing-8px;
      padding-top: 0;
    }

    &--role {
      border-radius: $border-radius-10px;
      display: inline-block;
      font-weight: $font-weight-500;
      padding: $spacing-2px $spacing-8px;
      text-transform: capitalize;

      &-blue-text {
        color: $color-blue-700;
        background-color: $color-blue-50;
      }

      &-green-text {
        background-color: $color-primary-100;
        color: $dark-green;
      }

      &-gray-text {
        background-color: $color-gray-200;
        color: $color-gray-900;
      }

      &-light-text {
        background-color: $color-gray-200;
        color: $dark-green;
      }
    }

    &--icon {
      svg {
        fill: $color-gray-600;
      }

      &-disable {
        fill: $color-gray-200;
      }
    }

    &--pagination {
      border-top: 1px solid $color-gray-200;
      display: flex;
      justify-content: flex-end;
      padding: $spacing-12px $spacing-24px $spacing-16px;
    }

    &--re-invite {
      border-radius: $border-radius-12px;
    }

    .table__body {
      .table__field {
        &:nth-child(1) {
          color: $color-gray-900;
          font-size: $font-14px;
          font-weight: $font-weight-500;
        }
      }
    }

    .table__field {
      &--align-left:last-child {
        text-align-last: center;

        .icon {
          cursor: pointer;
          justify-content: center;
        }
      }
    }

    .table__field-value {
      .admin-portal__table--re-inivte {
        border-color: $neon-green;
        border-radius: $border-radius-12px;
        color: $color-primary-700;
        font-size: $font-14px;
        font-weight: $font-weight-600;
      }
    }
  }
}
