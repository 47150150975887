@import 'styles/utils';

.check-email {
  &__row {
    align-items: baseline;
    color: $color-gray-600;
    display: flex;
    justify-content: center;
    margin-top: -$spacing-16px;

    button {
      background: transparent;
      border: 0;
      color: $color-primary-800;
      cursor: pointer;
      font-size: $font-14px;
      font-weight: $font-weight-600;
      line-height: $line-height-20px;
      outline: none;
    }
  }
}
