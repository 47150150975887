@import 'styles/variables';

.avatar-card {
  width: 84%;
}

.offer-listing {
  background-color: $color-gray-50;

  .tab-buttons {
    width: 84%;
    display: flex;
    flex-wrap: nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;
    padding: $spacing-4px;
    gap: 8px;

    &__kernel {
      font-size: $font-16px;
      font-weight: 500;
    }

    &__dried_nut_in_shell {
      font-size: $font-16px;
      font-weight: 500;
    }
  }

  &__marketplace {
    width: 84%;
    flex-flow: row wrap;

    &--filter-container {
      width: 24%;
    }

    &--items-container {
      width: 72%;

      &-pagination {
        border-top: 1px solid $color-gray-200;
        padding: $spacing-12px $spacing-24px $spacing-16px;
      }
    }

    &--overlay-cta {
      width: 100%;
    }
  }

  &__price-chart {
    &.line-chart-component {
      width: 100%;
    }
  }
}
