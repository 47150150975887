@import 'styles/utils';

.avatar-container {
  &__seller-profile {
    .rcl-avatar__content {
      .rcl-avatar__content-initials {
        color: $color-primary-600;
      }
    }
  }

  &__buyer-profile {
    .rcl-avatar__content {
      .rcl-avatar__content-initials {
        color: $color-blue-600;
      }
    }
  }

  &__upload {
    &--icon {
      border-radius: 50%;
      bottom: $spacing-none;
      position: relative;
      right: $spacing-36px;
    }
  }

  &__descriptions {
    &--admin {
      margin-left: $spacing-24px;
    }

    &--user-buyer {
      background-color: $color-blue-50;
      border-radius: $border-radius-16px;
      color: $color-blue-700;
      display: inline-block;
      font-size: $font-12px;
      font-weight: $font-weight-500;
      margin: $spacing-none;
      padding: $spacing-2px $spacing-8px;
      text-transform: capitalize;
    }

    &--user-seller {
      background-color: $color-primary-100;
      border-radius: $border-radius-16px;
      color: $color-primary-600;
      display: inline-block;
      font-size: $font-12px;
      font-weight: $font-weight-500;
      margin: $spacing-none;
      padding: $spacing-2px $spacing-8px;
      text-transform: capitalize;
    }

    &--user-affiliate-partner {
      background-color: $color-gray-200;
      border-radius: $border-radius-16px;
      color: $color-gray-900;
      display: inline-block;
      font-size: $font-12px;
      font-weight: $font-weight-500;
      margin: $spacing-none;
      padding: $spacing-2px $spacing-8px;
      text-transform: capitalize;
    }

    &--user-owner {
      background-color: $color-gray-200;
      color: $dark-green;
      border-radius: $border-radius-16px;
      display: inline-block;
      font-size: $font-12px;
      font-weight: $font-weight-500;
      margin: $spacing-none;
      padding: $spacing-2px $spacing-8px;
      text-transform: capitalize;
    }

    &--user-verification {
      background-color: $color-error-50;
      border-radius: $border-radius-16px;
      color: $color-error-700;
      display: inline-block;
      font-size: $font-12px;
      font-weight: $font-weight-500;
      margin-left: $spacing-10px;
      padding: $spacing-2px $spacing-8px;
      text-transform: capitalize;

      .icon {
        &--default {
          font-size: $font-16px;
          margin-left: $spacing-4px;
        }
      }
    }

    &--user-name {
      color: $color-gray-900;
      font-size: $font-30px;
      font-weight: $font-weight-600;
      line-height: $line-height-38px;
      margin: $spacing-4px $spacing-none $spacing-2px;
    }

    &--company-name,
    &--email {
      color: $color-gray-600;
      font-size: $font-16px;
      font-weight: $font-weight-400;
      line-height: $line-height-24px;
      margin: $spacing-2px $spacing-none;
    }
  }
}
