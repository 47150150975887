@import '../../../styles/utils.scss';

.registration-success {
  &__left-content {
    height: 100vh;
    position: fixed;
    width: 50%;
  }

  &__right-content {
    height: 100vh;
    margin-left: 50%;
    width: 50%;

    &--mail-logo-container {
      background: $color-primary-100;
      border-radius: 50%;
      padding: $spacing-14px;

      .mail-logo {
        height: $width-28px;
        width: $height-28px;
      }
    }

    &--title {
      color: $color-gray-900;
      font-size: $font-30px;
      font-weight: $font-weight-600;
      line-height: $line-height-38px;
      margin: $spacing-24px $spacing-none $spacing-none;
    }

    &--message {
      color: $color-gray-600;
      font-size: $font-16px;
      font-weight: $font-weight-400;
      line-height: $line-height-24px;
      margin: $spacing-12px $spacing-none $spacing-none;
    }

    &--email {
      font-weight: $font-weight-500;
      margin: $spacing-none $spacing-none $spacing-32px;
    }

    &--redirect-link {
      color: $color-gray-600;
      font-size: $font-14px;
      font-weight: $font-weight-600;
      line-height: $line-height-20px;
      text-decoration: none;
    }
  }
}
