@import 'styles/variables';

.sort-filter {
  padding: $spacing-32px $spacing-none $spacing-24px;

  &__result {
    color: $maastricht-blue;
    font-size: $font-18px;
    font-weight: $font-weight-600;
    line-height: $line-height-28px;
    margin: $spacing-none;
  }

  &__dropdown {
    width: $width-280px;

    .rcl-select-box {
      &__label {
        &--header {
          color: $maastricht-blue;
        }
      }
    }
  }
}
