@import '../../../styles/utils';

.icon {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  position: relative;
  > * {
    box-sizing: border-box;
  }

  &__content {
    border-radius: 50%;
    height: 2rem;
    position: relative;
    width: 2rem;

    svg {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &--bg-neutral {
      background-color: $blueish-black;
    }

    &--bg-success {
      background-color: $success-light;
    }

    &--bg-gray {
      background-color: $color-gray-700;
    }

    &--bg-warning {
      background-color: $warning-light;
    }

    &--bg-danger {
      background-color: $danger-light;
    }

    &--bg-default {
      background-color: $leaky-rust-light;
    }
  }

  &__label {
    margin-left: 10px;
  }

  &__tooltip {
    background-color: $leaky-rust;
    border-radius: 3px;
    color: $white;
    display: none;
    font-size: 14px;
    left: 50%;
    line-height: 1.5;
    max-width: 250px;
    padding: 2px 6px;
    position: absolute;
    top: calc(100% + 4px);
    transform: translateX(-50%);
    transition: transform 0.15s ease-in-out;
    z-index: 1;

    &-success {
      background-color: $success;
    }

    &-warning {
      background-color: $warning;
    }

    &-danger {
      background-color: $danger;
    }

    &-tertiary {
      background-color: $blueish-black;
    }
  }

  &:hover {
    .icon__tooltip {
      display: block;
    }
  }

  &--tertiary {
    svg {
      fill: $blueish-black;
    }
  }

  &--success {
    svg {
      fill: $success;
    }
  }

  &--warning {
    svg {
      fill: $warning;
    }
  }

  &--danger {
    svg {
      fill: $danger;
    }
  }

  &--default {
    svg {
      fill: $blueish-black-24;
    }
  }

  &--neutral {
    svg {
      fill: $white;
    }
  }

  &--xs {
    font-size: 0.6rem;

    .icon__content {
      height: 1.12rem;
      width: 1.12rem;
    }
  }

  &--tiny {
    font-size: 0.875rem;

    .icon__content {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  &--small {
    font-size: 1rem;

    .icon__content {
      height: 2rem;
      width: 2rem;
    }
  }

  &--normal {
    font-size: 1.25rem;

    .icon__content {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  &--medium {
    font-size: 1.5rem;

    .icon__content {
      height: 3rem;
      width: 3rem;
    }
  }

  &--large {
    font-size: 2rem;

    .icon__content {
      height: 3.5rem;
      width: 3.5rem;
    }
  }

  &--huge {
    font-size: 2.5rem;

    .icon__content {
      height: 5rem;
      width: 5rem;
    }
  }

  &--xl {
    font-size: 3rem;

    .icon__content {
      height: 7.5rem;
      width: 7.5rem;
    }
  }

  &--xxl {
    font-size: 3.5rem;

    .icon__content {
      height: 8.5rem;
      width: 8.5rem;
    }
  }

  &__rotate {
    transition: transform 0.3s ease;
  }
}
