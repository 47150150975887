@import 'styles/utils';

.food-safety {
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-12px;
  box-shadow: $protals-form-shadow;
  width: 100%;

  .product_type {
    &__title {
      color: $color-gray-900;
      font-size: $font-16px;
      font-weight: $font-weight-600;
      line-height: $line-height-32px;
      margin: $spacing-none;
      padding: $spacing-32px $spacing-24px $spacing-24px;
    }
  }

  &__title {
    border-bottom: 1px solid $color-gray-200;
    color: $color-gray-900;
    font-size: $font-24px;
    font-weight: $font-weight-600;
    line-height: $line-height-32px;
    margin: $spacing-none;
    padding: $spacing-32px $spacing-24px $spacing-24px;
  }

  &__section-header {
    border-bottom: 1px solid $color-gray-100;
    color: $color-gray-500;
    display: flex;
    font-size: $font-14px;
    font-weight: $font-weight-600;
    line-height: $line-height-20px;
    margin: $spacing-none;
    padding-bottom: $spacing-12px;

    &--required {
      color: $color-error-600;
      font-weight: $font-weight-500;
      margin-left: $spacing-5px;
    }
  }

  &__divider {
    border-bottom: 1px solid $color-gray-100;
  }

  &__submit-btn {
    background-color: $color-primary-600;
    border-radius: $border-radius-8px;
    color: $maastricht-blue;
    font-size: $font-14px;
    font-weight: $font-weight-600;
    line-height: $line-height-20px;
  }

  &__submit-btn {
    border-radius: $border-radius-8px;
    color: $maastricht-blue;
    font-size: $font-14px;
    font-weight: $font-weight-600;
    line-height: $line-height-20px;
  }

  &__base-error {
    padding-inline: $spacing-16px;
    padding-top: $spacing-16px;
    padding-bottom: $spacing-16px;
    border: 4px solid $color-error-500;
    background-color: $color-error-100;
    border-radius: $border-radius-16px;
  }
}
