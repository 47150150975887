@import 'styles/utils';

.avatar-upload-modal {
  font-family: 'Inter', sans-serif;

  .modal {
    &__container {
      max-width: $width-360px;
    }
  }

  .avatar-upload {
    margin: 0 auto;
  }
}
