@import 'styles/utils';

.line-chart-component {
  width: 57%;

  &__heading {
    color: $color-gray-900;
    font-size: $font-24px;
    font-weight: $font-weight-600;
    line-height: $line-height-32px;
    margin-bottom: $spacing-32px;
  }

  &__chart {
    background-color: $white;
    border: 1px solid $color-gray-200;
    border-radius: $border-radius-12px;
    padding: $spacing-24px;

    &--title {
      color: $color-gray-500;
      font-size: $font-14px;
      font-weight: $font-weight-600;
      line-height: $line-height-20px;
      margin: $spacing-none $spacing-none $spacing-4px;
    }

    &--subtitle {
      color: $color-gray-900;
      font-size: $font-24px;
      font-weight: $font-weight-600;
      line-height: $line-height-32px;
      margin: $spacing-none;
    }
  }
}
