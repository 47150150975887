@import 'styles/utils';

.contract-details {
  background: $base-white;
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-12px;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: $spacing-24px;

  &__pdf-viewer {
    height: 95vh;
    width: 100%;
  }
}
