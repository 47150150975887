@import 'styles/utils';

.avatar-dropdown {
  position: relative;

  &__button {
    background-color: inherit;
    border: 0;
    cursor: pointer;

    &:hover {
      background: inherit;
      border: 0;
    }
  }

  &__container {
    background-color: $white;
    border: 1px solid $color-gray-200;
    border-radius: $border-radius-8px;
    box-shadow: $dropdown-box-shadow;
    position: absolute;
    right: $spacing-24px;
    top: calc($spacing-40px + $spacing-16px);
    width: $width-240px;
    z-index: 2;

    &--username {
      color: $color-gray-700;
      font-size: $font-14px;
      font-weight: $font-weight-600;
      line-height: $line-height-20px;
      margin: $spacing-none;
    }

    &--company-name {
      color: $color-gray-600;
      font-size: $font-14px;
      font-weight: $font-weight-400;
      line-height: $line-height-20px;
      margin: $spacing-none;
    }

    &--menu {
      margin: $spacing-none;
      padding: $spacing-none;

      &-item {
        border-top: 1px solid $color-gray-200;
        color: $color-gray-700;
        cursor: pointer;
        display: flex;
        font-size: $font-14px;
        font-weight: $font-weight-500;
        gap: $spacing-8px;
        line-height: $line-height-20px;
        list-style: none;
        padding: $spacing-16px;
        text-decoration: none;
      }

      &-logout-button {
        background-color: inherit;
        border-top: solid $color-gray-200;
        border-width: 1px 0 0;
        color: $color-gray-700;
        cursor: pointer;
        font-size: $font-14px;
        font-weight: $font-weight-500;
        line-height: $line-height-20px;
        padding: $spacing-16px;
        width: 100%;

        &:hover {
          background: inherit;
          border-top: solid $color-gray-200;
          border-width: 1px 0 0;
        }

        .button__icon {
          .icon {
            svg {
              fill: $base-white;
            }
          }
        }
      }
    }
  }
}
