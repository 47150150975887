@import 'styles/utils';

.retract-bid-modal {
  width: 470px;

  &__header {
    border: 1px solid $color-gray-200;
    display: flex;
    justify-content: space-between;
    padding: $spacing-24px $spacing-24px $spacing-21px;

    &--text {
      color: $color-gray-900;
      font-size: $font-18px;
      font-weight: $font-weight-600;
      line-height: $line-height-28px;
    }
  }

  &__body {
    border-bottom: 1px solid $color-gray-200;
    padding: $spacing-20px $spacing-24px $spacing-none;

    &--text {
      color: $color-gray-700;
      font-size: $font-16px;
      font-weight: $font-weight-400;
      margin-bottom: $spacing-8px;
    }

    &--breakdown {
      font-size: $font-16px;
      padding-bottom: $spacing-24px;
      text-align: right;
    }
  }

  &__cta {
    display: flex;
    gap: $spacing-12px;
    padding: $spacing-24px;

    &-back,
    &-confirm {
      cursor: pointer;
      font-size: $font-16px;
      font-weight: $font-weight-600;
      line-height: $line-height-24px;
      padding: $spacing-10px $spacing-18px;
      width: 50%;
    }

    &-back {
      background: $white;
      border: 1px solid $color-gray-300;
      border-radius: $border-radius-8px;
      color: $color-gray-700;
    }

    &-confirm {
      background: $color-primary-600;
      border: 1px solid $color-primary-600;
      border-radius: $border-radius-8px;
      color: $color-gray-900;

      &--disabled {
        background: $tea-green;
        border: $spacing-none solid $color-primary-200;
        color: $color-gray-500;
      }
    }
  }
}
