@import 'styles/utils';

.password-visibility-icon {
  &__button {
    border: 0;
    margin-right: -$spacing-8px;

    &:hover {
      background-color: transparent;
      border: none;
    }
  }
}
