@import 'styles/utils';

@mixin set-active-color($color) {
  .rc-slider {
    &-rail {
      background-color: rgba($color, 0.1);
    }

    &-track {
      background-color: rgba($color, 0.9);
    }

    &-handle {
      border: 2px solid $color;
      transition: box-shadow 0.1s ease;

      &:hover {
        border-color: $color;
      }

      &:active {
        border-color: $color;
        box-shadow: 0 0 5px $color;
      }

      &-dragging {
        border-color: $color !important;
        box-shadow: 0 0 0 8px rgba($color, 0.3) !important;
      }

      &-click-focused:focus {
        border-color: $color;
      }
    }
  }
}

.range-slider {
  box-sizing: border-box;
  padding: 12px;
  width: 100%;

  * {
    box-sizing: border-box;
  }

  &__title {
    color: $color-gray-900;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 16px;
    width: 100%;
  }

  &--neutral {
    @include set-active-color($cool-gray-15);
  }

  &--warning {
    @include set-active-color($warning);
  }

  &--danger {
    @include set-active-color($danger);
  }

  &--success {
    @include set-active-color($neon-green);
  }

  .rc-slider {
    &-track {
      height: 8px;
    }

    &-handle {
      height: 20px;
      width: 20px;
    }

    &-disabled {
      background-color: transparent;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}
