@import 'styles/utils';

.accordion {
  width: 100%;

  &,
  * {
    box-sizing: border-box;
  }

  &__item {
    background-color: $white;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba($tertiary-accent, 0.16);
    box-sizing: border-box;
    color: $florid-tar;
    line-height: 1.25;
    margin: 10px 0;
    padding: 3px;

    &-header {
      align-items: center;
      background-color: inherit;
      border: 1px solid $white;
      border-radius: inherit;
      color: $black;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      font-weight: $font-weight-500;
      justify-content: space-between;
      padding: 15px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: rgba($tertiary-accent, 0.08);
      }
    }

    &-body {
      border: 1px solid $white;
      border-top-color: rgba($tertiary-accent, 0.2);
      font-size: 14px;
      height: 0;
      margin: 0 15px;
      min-height: 0;
      overflow: hidden;
      visibility: hidden;

      transition:
        height 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        padding 200ms;

      &--opened {
        height: 3rem;
        overflow: visible;
        padding: 15px 0;
        visibility: visible;
      }
    }
  }
}
