@import 'styles/utils';

$leaky-rust-light: rgba($leaky-rust, 0.16);
$tertiary-accent-with-alpha: rgba($tertiary-accent, 0.16);

.rcl-radio {
  align-items: center;
  border-color: $leaky-rust;
  color: $cool-gray-30;
  display: inline-flex;
  font-size: 1rem;
  margin: 0 1rem 0 0;

  &,
  * {
    box-sizing: border-box;
  }

  &__input {
    display: none;
  }

  &-circle {
    align-items: center;
    appearance: none;
    border-radius: 50%;
    cursor: pointer;
    height: calc(1.125rem + 0.25rem);
    justify-content: center;
    position: relative;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    width: calc(1.125rem + 0.25rem);
    border: 2.5px solid $tertiary-accent;

    &::before,
    &::after {
      background-color: $tertiary-accent;
      border-radius: 50%;
      content: '';
      display: block;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.2s ease;
    }

    &::after {
      background-color: $leaky-rust-light;
    }

    &:hover {
      &::after {
        transform: translate(-50%, -50%) scale(6);
      }
    }

    &-tooltip {
      padding: 4px 7px;
      &.rcl-tooltip.rcl-popover {
        padding: 4px 7px;
      }

      &--small {
        &.rcl-tooltip.rcl-popover {
          .rcl-popover__content {
            font-size: 12px;
          }
        }
      }

      &--large {
        &.rcl-tooltip.rcl-popover {
          .rcl-popover__content {
            font-size: 14px;
          }
        }
      }

      &--huge {
        &.rcl-tooltip.rcl-popover {
          .rcl-popover__content {
            font-size: 18px;
          }
        }
      }

      &--XL {
        &.rcl-tooltip.rcl-popover {
          .rcl-popover__content {
            font-size: 20px;
          }
        }
      }
    }

    &--checked {
      &::before {
        transform: translate(-50%, -50%) scale(0.8);
      }

      &::after {
        background-color: $tertiary-accent-with-alpha;
      }
    }

    &--not-checked {
      border: 2.5px solid $blueish-black-16;

      &::before {
        transform: translate(-50%, -50%) scale(0.8);
      }

      &::after {
        background-color: $blueish-black-16;
      }
    }

    &--check-icon {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      svg {
        fill: $white;
      }
    }

    &__with-check-icon {
      &--checked {
        background-color: $green;
        border-color: $green;
      }

      &--not-checked {
        background-color: $blueish-black-16;
        border-color: $blueish-black-16;
      }
    }
  }

  &__label {
    margin-left: 6px;
    color: $color-gray-700;
    font-size: $font-14px;
    line-height: $line-height-20px;
    font-weight: $font-weight-500;
  }

  &--small {
    font-size: 0.75rem;

    .rcl-radio__label {
      margin-left: 6px;
    }

    .rcl-radio-circle {
      @include size(12px, 12px);

      &::after {
        @include size(4px, 4px);
      }

      &--check-icon {
        font-size: 6px;
      }
    }
  }

  &--large {
    font-size: 1.125rem;

    .rcl-radio__label {
      margin-left: 8px;
    }

    .rcl-radio-circle {
      @include size(14px, 14px);

      &::after {
        @include size(5px, 5px);
      }

      &--check-icon {
        font-size: 8px;
      }
    }
  }

  &--huge {
    font-size: 1.375rem;

    .rcl-radio__label {
      margin-left: 10px;
    }

    .rcl-radio-circle {
      @include size(16px, 16px);

      &::after {
        @include size(6px, 6px);
      }

      &--check-icon {
        font-size: 10px;
      }
    }
  }

  &--XL {
    font-size: 1.5rem;

    .rcl-radio__label {
      margin-left: 10px;
    }

    .rcl-radio-circle {
      @include size(24px, 24px);

      &::after {
        @include size(6x, 6px);
      }

      &--check-icon {
        font-size: 10px;
      }
    }
  }

  &--disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}
