@import 'styles/utils';

.submit-buttons {
  background-color: $color-gray-50;
  padding-bottom: 32px;

  &__container {
    background-color: $base-white;
    border-color: $color-gray-200;
    border-radius: 0 0 $border-radius-12px $border-radius-12px;
    border-style: solid;
    border-width: 0 1px 1px;
    display: flex;
    justify-content: space-between;
    padding: $spacing-24px;

    &--previous {
      display: flex;
      flex-direction: row-reverse;
      gap: $spacing-8px;
    }

    &--save {
      display: flex;
      gap: $spacing-8px;
    }

    .button {
      border-radius: $border-radius-8px;
      font-weight: $font-weight-600;
    }
  }
}
