@import 'styles/utils.scss';

.login-form {
  &__container {
    margin-top: -$spacing-48px;
    width: 22.5rem;

    &--header {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      gap: $spacing-24px;

      &-logo {
        height: $spacing-48px;
        width: $spacing-48px;
      }

      &-texts {
        align-items: center;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;

        &-title {
          color: $color-gray-900;
          font-size: $font-30px;
          font-weight: $font-weight-600;
          line-height: $title-line-height;
          margin: $spacing-none $spacing-none $spacing-12px;
        }

        &-message {
          color: $color-gray-600;
          font-size: $font-16px;
          font-weight: $font-weight-400;
          line-height: $line-height-20px;
          margin: -$spacing-8px $spacing-none $spacing-32px;
        }
      }
    }

    &--form {
      margin-top: -$spacing-16px;

      .input__label {
        &--required::after {
          color: $danger;
        }
      }

      .submit-error {
        color: $danger;
        font-size: $font-14px;
        font-weight: $font-weight-400;
        line-height: $line-height-20px;
      }
    }

    &--row {
      align-items: last baseline;
      color: $color-gray-700;
      display: flex;
      font-size: $font-14px;
      font-weight: $font-weight-600;
      justify-content: space-between;
      line-height: $line-height-24px;
      margin-bottom: $spacing-24px;
      margin-top: $spacing-16px;
    }

    &--forgot-password {
      background-color: inherit;
      border: 0;
      color: $color-primary-700;
      cursor: pointer;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      outline: none;
      text-decoration: none;
    }

    &--submit-btn {
      border: 0;
      background-color: $color-primary-600;
      border-radius: $border-radius-8px;
      color: $maastricht-blue;
      font-size: $font-16px;
      font-weight: $font-weight-600;
      line-height: $line-height-20px;
      width: 100%;
    }
  }
}
