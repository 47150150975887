@import 'styles/utils';

.contract-details-header {
  margin: auto;
  width: 82%;

  &__sub-header {
    color: $color-gray-600;
    font-size: $spacing-16px;
    font-weight: $font-weight-400;
    line-height: $spacing-24px;
  }

  &__title {
    color: $color-gray-900;
    font-size: $font-30px;
    font-weight: $font-weight-600;
    line-height: $line-height-38px;
    padding-bottom: $spacing-4px;
  }

  &__container {
    align-items: center;
    display: flex;
    gap: $spacing-32px;
    justify-content: space-between;

    &--button-container {
      display: flex;
      gap: $spacing-32px;

      &-contracts-button {
        background: $white;
        border: 1px solid $color-gray-300;
        border-radius: $spacing-8px;
        box-shadow: 0 1px 2px 0 $light-box-shadow;
        color: $color-gray-700;
        font-size: $spacing-16px;
        font-weight: $font-weight-700;
        line-height: $spacing-20px;
        padding: $spacing-10px $spacing-16px;
        text-decoration: none;
        white-space: nowrap;

        &:hover {
          background-color: $color-gray-200;
        }

        &-green {
          background-color: $neon-green;
          border-radius: $spacing-8px;
          font-weight: $font-weight-600;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }

  &__information {
    color: $color-gray-600;
    display: flex;
    font-size: $font-14px;
    font-weight: $font-weight-500;
    gap: $spacing-16px;
    line-height: $line-height-20px;
    white-space: nowrap;

    &--value {
      font-weight: $font-weight-400;
      padding-left: $spacing-4px;

      &-green-text {
        color: $color-primary-600;
      }
    }
  }
}
