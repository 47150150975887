@import 'styles/utils';

.dnis-food-safety-details {
  border: 1px solid $color-gray-200;
  border-bottom-left-radius: $border-radius-10px;
  padding: $spacing-none $spacing-24px $spacing-24px;

  &__food_title {
    color: $color-gray-900;
    font-size: $font-18px;
    font-weight: $font-weight-600;
    line-height: $line-height-28px;
    margin: $spacing-24px $spacing-20px$spacing-20px;
  }

  &__title {
    color: $color-gray-900;
    font-size: $font-18px;
    font-weight: $font-weight-600;
    line-height: $line-height-28px;
    margin: $spacing-24px $spacing-none $spacing-20px;
  }

  &__table {
    > * {
      margin-bottom: $spacing-24px;
    }

    .table {
      border-top-left-radius: $border-radius-10px;
      border-top-right-radius: $border-radius-10px;

      * {
        border-top-left-radius: $border-radius-10px;
        border-top-right-radius: $border-radius-10px;
      }
    }
  }

  &__certificates {
    display: flex;
    flex-direction: column;
    gap: $spacing-20px;
  }
}
