@import 'styles/utils';

@mixin button-styles {
  background: $color-primary-600;
  border: 1px solid $color-primary-600;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 $light-box-shadow;
  color: $color-gray-900;
  font-size: $font-16px;
  font-weight: $font-weight-600;
  padding: $spacing-10px $spacing-18px;
  margin: $spacing-none $spacing-5px;
  @extend .listing-details-breadcrumb__button;
}

.listing-details-breadcrumb {
  color: $color-gray-600;
  display: flex;
  font-size: $font-14px;
  font-weight: $font-weight-500;
  justify-content: space-between;
  line-height: $line-height-20px;
  padding: $spacing-10px $spacing-none;

  &__actions {
    display: flex;
  }

  .decoration-none {
    color: $color-gray-600;
  }

  &__serial-id {
    color: $neon-green;
    font-weight: $font-weight-600;
  }

  &__oblique {
    color: $rich-black;
  }

  &__back-arrow {
    cursor: pointer;
    padding-right: $spacing-24px;
  }

  &__button {
    align-items: center;
    cursor: pointer;
    display: flex;
  }

  &__un-publish {
    @include button-styles;

    &-icon {
      padding-left: $spacing-8px;
    }
  }

  &__delete {
    @include button-styles;

    color: $white;
    background: $danger;
    border: 1px solid $tertiary-accent-light;
  }

  &__edit-listing {
    @include button-styles;

    &-disabled {
      background: $tea-green;
      border: $spacing-none solid $color-primary-200;
      border-radius: $spacing-8px;
      color: $color-gray-500;
      display: flex;
      align-items: center;
      gap: $spacing-5px;
      justify-content: center;
    }
  }

  &__unfollow-listing {
    @include button-styles;

    &-icon {
      padding-left: $spacing-8px;
    }

    background: $color-gray-100;
    border: 1px solid $color-gray-600;
  }

  &__follow-listing {
    @include button-styles;

    &-icon {
      padding-left: $spacing-8px;
    }

    background: $color-gray-100;
  }
}
