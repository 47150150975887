@import 'styles/variables';

.trading-cards {
  padding: $spacing-96px $spacing-none;

  &__heading {
    color: $maastricht-blue;
    font-size: $font-36px;
    font-weight: $font-weight-600;
    letter-spacing: -$letter-space-72px;
    line-height: $line-height-44px;
    margin: $spacing-none $spacing-none $spacing-64px;
    text-align: center;
    text-transform: capitalize;
  }

  &__card {
    background-color: $base-white;
    border: 1px solid $color-gray-200;
    border-radius: $border-radius-12px;
    padding: $spacing-40px;
    width: 25%;

    &--card-number {
      background-color: $color-primary-600;
      border-radius: 50%;
      color: $base-white;
      font-size: $font-20px;
      font-weight: $font-weight-500;
      height: $height-48px;
      line-height: $line-height-30px;
      width: $width-48px;
    }

    &--heading {
      color: $maastricht-blue;
      font-size: $font-20px;
      font-weight: $font-weight-600;
      line-height: $line-height-30px;
      margin: $spacing-36px $spacing-none $spacing-8px;
    }

    &--description {
      color: $color-gray-600;
      font-size: $font-16px;
      font-weight: $font-weight-400;
      line-height: $line-height-24px;
      margin: $spacing-none;
    }
  }

  &__cta {
    background-color: $base-white;
    border: 1px solid $color-gray-300;
    border-radius: $border-radius-8px;
    box-shadow: $button-box-shadow;
    color: $color-gray-700;
    font-size: $font-16px;
    font-weight: $font-weight-600;
    line-height: $line-height-24px;
    margin-top: $spacing-64px;
    padding: $spacing-12px $spacing-20px;
    text-decoration: none;
  }
}
