@import 'styles/utils';

.offered-price {
  border-top-right-radius: $border-radius-10px;
  padding: $spacing-24px $spacing-24px $spacing-12px;

  &-title {
    background: $color-gray-50;
    border: 1px solid $color-gray-200;
    border-radius: $border-radius-10px $border-radius-10px 0 0;
    color: $color-gray-600;
    display: flex;
    font-size: $font-14px;
    font-weight: $font-weight-600;
    gap: $spacing-10px;
    line-height: $line-height-20px;
    padding: $spacing-12px $spacing-16px;
  }

  &-body {
    border: 1px solid $color-gray-200;
    border-radius: 0 0 $border-radius-10px $border-radius-10px;
    padding: $spacing-16px;

    &__data {
      margin-bottom: $spacing-20px;

      &--concluded {
        margin-bottom: $spacing-none;
      }

      &--currency {
        color: $color-gray-900;
        font-size: $font-24px;
        font-weight: $font-weight-600;
        line-height: $line-height-32px;
        padding-right: $spacing-8px;
      }

      &--unit {
        color: $color-gray-500;
        font-size: $font-14px;
        font-weight: $font-weight-400;
        line-height: $line-height-20px;
      }
    }

    .rcl-popover__parent {
      width: 100%;
    }

    &__accept-order-button {
      background: $color-primary-600;
      border: 1px solid $color-primary-600;
      border-radius: $border-radius-8px;
      box-shadow: $spacing-none $spacing-1px $spacing-2px $spacing-none $light-box-shadow;
      color: $maastricht-blue;
      cursor: pointer;
      font-size: $font-16px;
      font-weight: $font-weight-600;
      line-height: $line-height-24px;
      padding: $spacing-10px $spacing-18px;
      width: 100%;

      &--disabled {
        background: $tea-green;
        border: $spacing-none solid $color-primary-200;
        color: $color-gray-500;
      }
    }

    &__adjust-offer-button {
      background: $white;
      border: 1px solid $color-gray-300;
      border-radius: $border-radius-8px;
      box-shadow: $spacing-none $spacing-1px $spacing-2px $spacing-none $light-box-shadow;
      color: $color-gray-700;
      cursor: pointer;
      font-size: $font-16px;
      font-weight: $font-weight-600;
      line-height: $line-height-24px;
      padding: $spacing-10px $spacing-18px;
      width: 100%;
    }
  }
}
