@import 'styles/utils';

.test-results {
  &__header {
    background: $color-gray-50;
    border: 1px solid $color-gray-200;
    border-bottom: $spacing-1px solid $color-gray-200;
    border-top-left-radius: $border-radius-10px;
    border-top-right-radius: $border-radius-10px;
    color: $color-gray-500;
    font-size: $font-14px;
    font-weight: $font-weight-600;
    line-height: $line-height-20px;
    padding: $spacing-12px $spacing-24px;
  }

  &__body {
    border: 1px solid $color-gray-200;
    border-bottom-left-radius: $border-radius-10px;
    border-bottom-right-radius: $border-radius-10px;
    padding: $spacing-20px $spacing-24px;

    &-item {
      color: $color-gray-600;
      font-size: $font-16px;
      font-weight: $font-weight-400;

      &--bold {
        color: $color-gray-900;
        font-weight: $font-weight-500;
        padding-left: $spacing-8px;
      }
    }
  }
}
