@import 'styles/variables';

.admin-navbar {
  &__main {
    background-color: $white;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-left: $spacing-48px + $spacing-24px;
  }

  &__header {
    background: $white;
    border-bottom: 1px solid $color-gray-200;
    color: $color-gray-600;
    display: flex;
    font-size: $font-14px;
    font-style: normal;
    font-weight: $font-weight-500;
    justify-content: center;
    line-height: 1.25;
    width: 100%;

    &--container {
      align-items: center;
      align-self: stretch;
      display: flex;
      height: $spacing-48px + $spacing-24px;
      justify-content: space-between;
      padding: 0 $spacing-32px;
      width: 95%;
    }
  }
}
