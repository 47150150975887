@import 'styles/utils';

.stb {
  background: $neon-green;
  bottom: calc($spacing-16px * 4.8);
  height: $spacing-64px;
  position: fixed;
  right: 4%;
  width: $spacing-64px;

  .button {
    &__icon {
      margin-right: 0;
    }
  }

  &--icon {
    svg {
      fill: $white;
    }
  }
}
