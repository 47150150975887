@import 'styles/utils';

.empty-content {
  &__heading {
    color: $maastricht-blue;
    font-size: $font-16px;
    font-weight: $font-weight-600;
    line-height: $line-height-24px;
    margin: $spacing-10px $spacing-none $spacing-none;
    text-align: center;
  }

  &__description {
    color: $color-gray-600;
    font-size: $font-14px;
    font-weight: $font-weight-400;
    line-height: $line-height-20px;
    margin: $spacing-none;
    text-align: center;
  }
}
