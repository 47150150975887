@import 'styles/variables';

.avatar-container {
  &__card {
    width: 84%;

    &--welcome-text {
      color: $color-gray-600;
      font-size: $font-16px;
      font-weight: $font-weight-400;
      line-height: $line-height-24px;
      margin: $spacing-none;
    }

    &--user-name {
      color: $maastricht-blue;
      font-size: $font-30px;
      font-weight: $font-weight-600;
      line-height: $line-height-38px;
      margin: $spacing-4px $spacing-none;
    }

    &--company-name {
      color: $color-gray-600;
      font-size: $font-16px;
      font-weight: $font-weight-400;
      line-height: $line-height-24px;
      margin: $spacing-none;
    }

    &--redirect-button {
      background-color: $neon-green;
      border: 1px solid $periwinkle-gray;
      border-radius: $border-radius-8px;
      box-shadow: 0 1px 2px 0 $light-box-shadow;
      color: $black;
      font-size: $font-16px;
      font-weight: $font-weight-600;
      line-height: $line-height-24px;
      padding: $spacing-10px $spacing-18px;
      text-decoration: none;
      white-space: nowrap;

      &:hover {
        background-color: $kelly-green;
        color: $white;
      }
    }
  }
}
