@import 'styles/utils';

.progress-bar {
  background-color: $grey-shade;
  margin-bottom: 5px;
  overflow: hidden;
  width: 80px;

  &__hint-label {
    color: $casual-azure;
    font-size: $font-14px;
    font-weight: $font-weight-500;
    margin-right: 10px;
    opacity: 0.5;
  }

  &--small {
    border-radius: 3px;
    height: 8px;
  }

  &--medium {
    border-radius: 10px;
    height: 20px;
  }

  &--large {
    border-radius: 15px;
    height: 30px;
  }

  &__complete {
    &-label {
      color: $casual-azure;
      font-size: $font-14px;
      font-weight: $font-weight-500;
      margin-right: 10px;

      &-text {
        margin-left: 5px;
      }
    }
  }
}
